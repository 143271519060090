const initState = {
    loginModalOpen: false,
    registrationModalOpen: false
}

export const authReducer = (state = initState, action) => {
    switch(action.type) {
        case 'TOGGLE_LOGIN_MODAL': 
            return ({
                loginModalOpen: action.payload,
                registrationModalOpen: false
            });
        case 'TOGGLE_REGISTRATION_MODAL':
            return ({
                registrationModalOpen: action.payload,
                loginModalOpen: false
            });
        default: 
            return state;
    }
}