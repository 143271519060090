import React, { useEffect, useState } from "react";
import { Card, Col, Row, Button, Divider } from "antd";
import { useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { BsPlay, BsCheck2 } from 'react-icons/bs';

import useFetch from "../../_fetch";
import { sanitize } from "../../sanitizer";

import "./styles.less";
import { Loader } from "../loader";

const CourseCard = ({ course, type }) => {

	const [cookie, setCookie] = useCookies(['cart']);

	const navigate = useNavigate();

	return (
		<Col key={course._id} xs={23} md={12} lg={8}>
			<Card
				cover={
					<img
						alt={course.name}
						src={course.banner_link}
						className="img img-fluid"
						style={{ padding: 1 }}
					/>
				}
				onClick={() => navigate(`/course_overview?id=${course._id}`)}
				className="course-card pointer h-100"
			>
				<Meta title={course.name} />
				<p
					dangerouslySetInnerHTML={{
						__html: sanitize(course.description),
					}}
					className="mt-3 mb-4"
				/>

				{
					course.trainer.name ?
						<div className="mt-auto">
							<Row gutter={8}>
								<Col span={4}>
									<img
										src={course.trainer?.image}
										className="img img-fluid rounded-circle"
										alt={course.trainer.name[0]}
									/>
								</Col>
								<Col span={20} className="h-100 d-flex flex-column justify-content-center">
									<h6 className="my-1">{course.trainer.name}</h6>
									<p className="mb-0 text-muted">{course.trainer.qualification}</p>
								</Col>
							</Row>
						</div>
						:
						null
				}

				<Divider className='my-3' />

				<div className="d-flex">
					{
						type === 'INPROGRESS'
							?
							<Button
								className={`w-100 ${course.completion === 100 ? 'bg-success border-success' : ''}`}
								type="primary"
							>
								{
									course.completion === 0
										?
										<span className="d-flex align-items-center justify-content-center">
											<BsPlay size={25} className="pe-2" /> Start Learning
										</span>
										:
										course.completion === 100
											?
											<span className="d-flex align-items-center justify-content-center">
												<BsCheck2 size={25} className="pe-2" /> Completed
											</span>
											:
											<span className="d-flex align-items-center justify-content-center">
												<BsPlay size={25} className="pe-2" /> Continue Learning
											</span>
								}
							</Button>
							:
							<React.Fragment>
								<Button className="w-100 me-2">Preview</Button>
								<Button
									type="primary"
									htmlType="button"
									onClick={(ev) => {
										ev.stopPropagation();
										if (cookie.cart) {
											if (cookie.cart.split(',').includes(course._id)) {
												navigate('/cart')
											} else {
												setCookie('cart', `${cookie.cart},${course._id}`)
											}
										} else {
											setCookie('cart', course._id)
										}
									}}
									className="w-100 ms-2"
								>
									{cookie.cart?.split(',').includes(course._id) ? 'Checkout Cart' : 'Add To Cart'}
								</Button>
							</React.Fragment>
					}
				</div>
			</Card>
		</Col>
	)
}

const { Meta } = Card;

export function Dashboard() {

	const [courses, setCourses] = useState([]);
	const [inprogress, setInprogress] = useState([]);
	const [loading, setLoading] = useState(true);

	let _fetch = useFetch();

	useEffect(() => {
		_fetch(`${process.env.REACT_APP_API_URL}/course`)
			.then(async (res) => {
				if (res.status === 200) {
					let result = await res.json();
					let { inprogress, courses } = result.response;
					setCourses(courses);
					setInprogress(inprogress);
				} else {
				}
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => setLoading(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (loading) {
		return <Loader />
	}

	return (
		<div className="w-100 dashboard">


			{
				Array.isArray(inprogress) && inprogress.length ?
					<section className="shadow">
						<div className="container">

							<div className="section-title">
								<h2>Courses Inprogress</h2>
							</div>

							<Row gutter={[32, 16]}>
								{
									inprogress.map((e, i) => (
										<CourseCard
											key={e._id}
											course={e}
											type='INPROGRESS'
										/>
									))
								}
							</Row>
						</div>
					</section>
					:
					null
			}

			{
				Array.isArray(courses) && courses.length ?
					<section className="shadow">
						<div className="container">

							<div className="section-title">
								<h2>All Courses</h2>
							</div>

							<Row gutter={[32, 16]}>
								{
									courses.map((e, i) => (
										<CourseCard
											key={e._id}
											course={e}
											type='ALL'
										/>
									))
								}
							</Row>
						</div>
					</section>
					:
					null
			}

		</div>
	);
}
