import React, { useRef } from 'react';
import { Button, Col, Divider, Menu, Progress, Row, Spin } from "antd";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { CheckOutlined, MailOutlined } from "@ant-design/icons";
import { AiOutlineLock, AiOutlineFileText, AiOutlineVideoCamera } from 'react-icons/ai';
import { LoadingOutlined } from '@ant-design/icons';

import useFetch from "../../_fetch";

import './styles.less';
import { sanitize } from "../../sanitizer";

export default function Course() {

	const { SubMenu } = Menu;

	const [content, setContent] = useState({});
	const [loading, setLoading] = useState(false);
	const [loadingContent, setLoadingContent] = useState(false);
	const [contentError, setContentError] = useState('');
	const [courseInfo, setCourseInfo] = useState({});
	const [progress, setProgress] = useState({});
	const [allConcepts, setAllConcepts] = useState([]);
	const [conceptDict, setConceptDict] = useState([]);
	const [selectedKey, setSelectedKey] = useState([]);

	const search = useLocation().search;
	const id = new URLSearchParams(search).get("id");

	const videoRef = useRef(null);

	let _fetch = useFetch();

	const spinIcon = <LoadingOutlined style={{ fontSize: 200, color: '#2717bd' }} spin />;

	useEffect(() => {
		setLoading(true);
		_fetch(`${process.env.REACT_APP_API_URL}/course/${id}`)
			.then(async (res) => {
				if (res.status === 200) {
					let result = await res.json();
					console.log(result.response);
					setCourseInfo(result.response);
					if (result.response.syllabus.length) {
						setContent({
							type: 'INTRO',
							title: result.response.name,
							content: result.response.intro_video
						});
						setProgress(result.response.progress ?? {});
						let ind = 0, obj = {};
						let allConcepts = result.response.syllabus.map((e, i) => {
							return e.topics.map((t, j) => {
								return t.concepts.map((c, k) => {
									obj[c._id] = ind;
									ind++;
									return ({ i, j, k })
								})
							})
						});
						console.log(obj);
						setAllConcepts(allConcepts.flat(2));
						setConceptDict(obj);
					}
				} else {
				}
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {

		if (content.type === 'VIDEO') {
			let _timer;

			function videoListener() {
				console.log(content);
				if (progress[content.concept_id]) {
					this.currentTime = this.duration * progress[content.concept_id] / 100;
				}
			}

			function videoStarted() {
				console.log(content);
				_timer = setInterval(async () => {
					try {

						console.log(videoRef?.current.currentTime, videoRef?.current.duration);

						let _progress = (videoRef?.current.currentTime * 100) / videoRef?.current.duration;

						if(_progress > 97) setProgress({...progress, [content.concept_id]: 100});

						if (videoRef?.current.currentTime)
							await _fetch(`${process.env.REACT_APP_API_URL}/course/${courseInfo._id}/${content.chapter_id}/${content.topic_id}/${content.concept_id}?progress=${_progress}`, { method: "POST" })
					} catch (err) {
						console.log(err);
					}
				}, 3000);
			}

			function videoPaused() {
				if (_timer) {
					clearInterval(_timer)
					_timer = null;
				}
			}

			if (videoRef.current?.addEventListener) {

				videoRef.current?.addEventListener('loadedmetadata', videoListener, false);
				videoRef.current?.addEventListener('play', videoStarted, false);
				videoRef.current?.addEventListener('pause', videoPaused, false);

				let _videoRef = videoRef.current;
				return () => {
					try {
						_videoRef?.removeEventListener('loadedmetadata', videoListener, false);
						_videoRef?.removeEventListener('play', videoStarted, false);
						_videoRef?.removeEventListener('pause', videoPaused, false);

						if (_timer) {
							clearInterval(_timer);
							_timer = null;
						}

					} catch (err) {

					}
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [content]);

	const handleClick = async (ind) => {

		setLoadingContent(true);
		setContentError('');

		console.log(allConcepts, ind);

		let { i, j, k } = allConcepts[ind];

		setSelectedKey([`c${i + 1}t${j + 1}cp${k + 1}`]);

		let course = courseInfo.syllabus;

		let chapter_id = course[i]._id;
		let topic_id = course[i].topics[j]?._id;
		let concept_id = course[i].topics[j].concepts[k]._id;
		try {
			let res = await _fetch(`${process.env.REACT_APP_API_URL}/course/${courseInfo._id}/${chapter_id}/${topic_id}/${concept_id}`)
			let result = await res.json()
			if (res.status === 200) {
				setContent({
					type: result.response.type,
					title: result.response.title,
					content: result.response.content,
					chapter_id,
					topic_id,
					concept_id,
					ind
				});
			} else {
				setContentError(result.message)
			}
		} catch (err) {
			setContentError('Some error occurred please try again');
		} finally {
			setLoadingContent(false)
		}
	};

	const handleNext = () => {
		if (allConcepts.length !== content.ind + 1) {
			handleClick(content.ind + 1);
		}
	}

	const handleBack = () => {
		if (content.ind !== 0) {
			handleClick(content.ind - 1);
		}
	}

	const complete = async () => {
		try {
			setProgress({...progress, [content.concept_id]: 100});
			await _fetch(`${process.env.REACT_APP_API_URL}/course/${courseInfo._id}/${content.chapter_id}/${content.topic_id}/${content.concept_id}?progress=100`, { method: "POST" })
		} catch (err) {
			console.log(err);
		}
	}

	if (loading) {
		return (
			<div className="h-100 w-100 d-flex align-items-center justify-content-center" style={{ fontSize: 200 }}>
				<Spin indicator={spinIcon} />
			</div>
		)
	}

	return (
		<div className="w-100 h-100">
			<Row className="align-items-stretch h-100">

				<Col xs={2} md={6} lg={5} xxl={4}>
					<Menu
						// onClick={handleClick}
						defaultOpenKeys={courseInfo?.syllabus?.map((e, i) => `c${i + 1}`)}
						mode="inline"
						className="h-100 w-100 course-menu"
						selectedKeys={selectedKey}
					>
						<h5
							className="p-3 text-white pointer"
							onClick={() => {
								setContent({
									type: 'INTRO',
									title: courseInfo.name,
									content: courseInfo.intro_video
								});
							}}
						>
							{courseInfo.name}
						</h5>

						{
							courseInfo?.syllabus?.map((e, i) => (
								<SubMenu key={`c${i + 1}`} icon={<MailOutlined />} title={e.name}>
									{e?.topics?.map((f, j) => (
										<Menu.ItemGroup key={`c${i + 1}t${j + 1}`} title={f.title}>
											{f?.concepts?.map((g, k) => (
												<Menu.Item
													key={`c${i + 1}t${j + 1}cp${k + 1}`}
													onClick={() => {
														if (courseInfo.enrolled)
															handleClick(conceptDict[g._id])
													}}
													disabled={!courseInfo.enrolled}
												>
													<div className='d-flex align-items-center w-100'>
														{
															g.type === 'HTML'
																?
																<AiOutlineFileText size={20} />
																:
																<AiOutlineVideoCamera size={20} />
														}
														<span className='ps-2'>{g.title}</span>
														{
															!courseInfo.enrolled ?
																<span className='ms-auto me-1'><AiOutlineLock size={20} /></span>
																:
																<Progress
																	type='circle'
																	percent={progress[g._id] ? Math.floor(progress[g._id]) : 0}
																	width={20}
																	className='ms-auto'
																	trailColor='#ddd'
																/>
														}
													</div>
												</Menu.Item>
											))}
										</Menu.ItemGroup>
									))}
								</SubMenu>
							))
						}
					</Menu>
				</Col>

				<Col xs={22} md={18} lg={19} xxl={20} className='h-100'>

					<div
						className={`h-100 w-100 align-items-center justify-content-center ${loadingContent ? 'd-flex' : 'd-none'}`}
						style={{ fontSize: 200 }}
					>
						<Spin indicator={spinIcon} />
					</div>

					<div
						className={`h-100 w-100 d-flex align-items-center justify-content-center ${!loadingContent && contentError ? 'd-flex' : 'd-none'}`}
					>
						<p className='text-danger'>{contentError}</p>
						<Button onClick={handleClick} type='primary' className='px-5' >Retry</Button>
					</div>

					<Row className={`p-5 h-100 w-100 ${!loadingContent && !contentError ? '' : 'd-none'}`}>
						<Col span={24}>
							<h3 className="mb-4">{content.title}</h3>
						</Col>
						<Col xs={24} md={20} lg={16} hidden={content.type !== 'HTML'}>
							<div className="w-100 display-html" dangerouslySetInnerHTML={{ __html: content.content }} />
						</Col>
						<Col xs={24} md={20} hidden={content.type !== 'VIDEO'}>
							<video
								className="img img-fluid"
								src={content.content}
								autoPlay={true}
								controls={true}
								ref={videoRef}
							/>
						</Col>

						<Col xs={24} md={20} hidden={content.type !== 'INTRO'}>
							<video
								className="img img-fluid"
								src={content.content}
								controls={true}
								autoPlay
							/>
						</Col>

						{
							content.type !== 'INTRO' &&
							<Divider />
						}

						<Col xs={24} md={20} hidden={content.type !== 'INTRO'}>
							<p className="mt-4" dangerouslySetInnerHTML={{ __html: sanitize(courseInfo.description) }} />
						</Col>

						<Col xs={24} className={`mt-auto pt-5 d-flex ${content.type !== 'INTRO' ? 'd-flex' : 'd-none'}`}>
							<Button className='ms-auto me-2' onClick={handleBack}>Back</Button>
							{
								content.type !== 'VIDEO' && 
								<Button
									className='mx-2 d-flex align-items-center'
									type='primary'
									onClick={progress[content.concept_id] === 100 ? ()=>{} : complete}
								>
									{progress[content.concept_id] === 100 ? <React.Fragment><CheckOutlined className='pe-1' /> Completed</React.Fragment> : 'Mark As Completed'}
								</Button>
							}
							<Button className='mx-2' onClick={handleNext}>Next</Button>
						</Col>

					</Row>

				</Col>
			</Row>
		</div >
	);
}
