import React from 'react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.css';

export default function Testimonial() {

    const testimonials = [
        {
            name: 'Gowardhan',
            batch: 'Advanced Batch',
            message: 'I found the training was very helpful and interactive. The trainer is very informed and the course content is very well loaded with all the functions of security. The content in training helps target the right areas and also helps traverse through the ocean of information on Internet. This has helped bring the confidence by hands on activities to confidently start the job in Cyber security.'
        }, {
            name: 'Lakshmi',
            batch: 'Starter Batch',
            message: 'Excellent course material and assignments helped me build on knowledge, reinforce, and then expand. The support from tutor was great, while the course was well paced, and pitched at a level which I could understand, being from a non technical end.'
        }, {
            name: 'Smita Roy',
            batch: '',
            message: 'It was a pleasure to learn the key skills of GRC and DevOps from this fantastic training. The trainer was really knowledgeable and supporting.'
        }, {
            name: 'Priya C',
            batch: '',
            message: 'Devsecops provides excellent training in Cybersecurity. Chaitanya is a very dedicated mentor who is available all the time and clears our doubts with detailed diagrams. Chaitanya explains all the concepts in simple words which is easily understandable by beginners. He also provides support post-training which we couldn\'t expect from other training institutes.'
        }, {
            name: 'Ravi Reddy',
            batch: '',
            message: 'Chaitanya has commendable knowledge on Cybersecurity and Cloud Security. He is approachable anytime, patient enough to answer all of my queries. It was well planned training sessions with good documentation. Training was fully interactive with live examples. This training improved my knowledge on Security area. Looking forward to attend upcoming training sessions.'
        }, {
            name: 'Subbu G',
            batch: '',
            message: 'Chaitanya is quite knowledgeable on Cybersecurity and I have really enjoyed his sessions. Above all, he\'s patient and approachable, which are key qualities of a good tutor -'
        }, {
            name: 'Kamrul Hassan',
            batch: '',
            message: 'Thank you for your all efforts to make this course interactive. We have learned a lot and had a wonderful time together. Hope to see you in your next batch.'
        }, {
            name: 'Kumar PV',
            batch: '',
            message: 'It\'s my privilege to join Proper Devsecops. I learned IT concepts from the scratch to in details and the programme structure was designed suits working, students and home makers. Instructor Chaitanya, was so patient while teaching and always a message or call away for any kind of queries, which even helped me to achieve my dream career in IT as quick. Chaitanya is always available as a mentor to clarify my doubts even I left the training after 6 months. That\'s the big advantage we never expect from any typical institute.'
        }, {
            name: 'Naga',
            batch: '',
            message: 'Chaitanya is very friendly and excellent in teaching as he explains very patiently whenever a question or real world incidents are asked. He gives real time examples and explains in detail from end to end. He tries to get the student to understand properly about the subject and gives assignments for every lesson. I have changed my career to Information Security which is helping me a lot with his training.'
        }, {
            name: 'S S',
            batch: '',
            message: 'Chaitanya has good and in-depth knowledge of Cybersecurity and related.He is very accommodative and flexible in training.Answers any queries patiently and provides good support. I\'d definitely recommend him! Thank you Chaitanya for your kind support!'
        }, {
            name: 'Sridevi SN',
            batch: '',
            message: 'A perfect mentor who supports all the trainees in the group.'
        }, {
            name: 'Karthik Botla',
            batch: '',
            message: 'Excellent Service and training is very good.'
        }, {
            name: 'IH',
            batch: '',
            message: 'Chaitanya is very honest and takes his full effort in teaching. He very patiently explains whenever a question is asked. He does not mind taking the extra effort in clarifying. His expert knowledge and real-time examples helped me in learning a lot from him. Well done chaitanya!!!!'
        }, {
            name: 'GR',
            batch: '',
            message: 'The course is very well structured and Chaitanya is very experienced and expert in cloud and Cyber Security. He is always approachable to resolve doubts. This course certainly prepares you to Cyber Security roles.'
        }, {
            name: 'Kala Nareddy',
            batch: '',
            message: 'Excellent training,Good explanation on GDPR,ISO,PCIFrameworks,cloud based controls.Given real time projects,make us to work on real time use cases.'
        }, {
            name: 'Girish',
            batch: '',
            message: 'Very professional training, deep clarity on security frameworks and clear explanations for the same in mapping to current industry standards. Very helpful for anyone to get into security industry.'
        }
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 10,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
    };

    return (
        <div className='row justify-content-center testimonial'>
            <div className="col-lg-8">

                <Slider {...settings}>

                    {
                        testimonials.map((e, i) => (
                            <div className='testimonial-item' key={`testimonial-${i}`}>
                                <h3>{e.name}</h3>
                                <h4>{e.batch}</h4>
                                <p>{e.message}</p>
                            </div>
                        ))
                    }

                </Slider>

            </div>

            {/* TrustBox widget - Micro Review Count  */}

            <div className="trustpilot-widget col-12 mt-5 text-center" data-locale="en-GB" data-template-id="5419b6a8b0d04a076446a9ad" data-businessunit-id="5e303aa9cedbfa0001a040aa" data-style-height="24px" data-style-width="100%" data-theme="light">
                <a href="https://uk.trustpilot.com/review/proper-training.com" target="_blank" rel="noopener noreferrer">Trustpilot</a>
            </div>

        </div>
    )

}