import React from 'react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Partners() {

    const images = [
        '/img/department_for_culture_media_and_sports.jpeg',
        '/img/cyber101.jpeg',
        '/img/cylon.jpg',
        '/img/Cyber_Runway_Member_Grow.png',
        '/img/Startup_Loft.jpg'
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 10,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true
    };

    return (
        <div className='row justify-content-center partners'>
            <div className="col-lg-12 col-xl-10">
                <Slider {...settings}>
                    {
                        images.map((e, i) => (
                            <div className='w-100 px-4' key={`partner-${i}`}>
                                <img src={e} className='img img-fluid my-auto' alt='..' />
                            </div>
                        ))
                    }
                </Slider>
            </div>
        </div>
    )
}