import React, { useEffect, useState } from "react";
import { Button, Card, Col, Input, Row } from "antd";
import { useCookies } from "react-cookie";
import update from 'immutability-helper';
import { useElements, useStripe, CardElement } from "@stripe/react-stripe-js";
import { Link, useNavigate } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";

import useFetch from "../../_fetch";
import { Loader } from "../loader";
import CardSection from "./card";

export function Cart() {

	const [cookies, setCookie] = useCookies(['cart']);
	const { name, email } = useSelector(st => st.user);

	const [loading, setLoading] = useState(true);
	const [courses, setCourses] = useState([]);
	const [promo, setPromo] = useState("");
	const [promoObj, setPromoObj] = useState({});
	const [processing, setProcessing] = useState(false);
	const [processingPromo, setProcessingPromo] = useState(false);
	const [error, setError] = useState('');
	const [cardValidationError, setCardValidationError] = useState('');

	const stripe = useStripe();
	const elements = useElements();
	let _fetch = useFetch();
	let navigate = useNavigate();

	useEffect(() => {

		if (cookies.cart.length === 0) {
			setLoading(false);
			return;
		}

		_fetch(`${process.env.REACT_APP_API_URL}/course/list?list=${JSON.stringify(cookies.cart.split(','))}`)
			.then(async res => {
				let result = await res.json();
				if (res.status === 200) {
					setCourses(result.response);
				} else {
					toast.error('Some error occurred while processing your cart');
				}
			})
			.catch(err => {
				console.log(err);
			})
			.finally(() => {
				setLoading(false);
			})

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (elements?.getElement(CardElement))
			elements.getElement(CardElement).on('change', (ev) => {
				if (ev.error)
					setCardValidationError(ev.error.message);
				else setCardValidationError('');
			});
	}, [elements]);

	function applyPromo() {
		setPromoObj({});
		setProcessingPromo(true);

		if (!promo) return;

		_fetch(`${process.env.REACT_APP_API_URL}/promo?promo=${promo}`)
			.then(async res => {
				let result = await res.json();
				if (res.status === 200) {
					if (result.response.course_id?._id) {
						if (courses.find(e => e._id === result.response.course_id._id)) {
							setPromoObj(result.response);
							toast.success('Promo applied!!');
						} else {
							toast.error(`This promo can be applied only on ${result.response.course_id.name} course`);
						}
					} else {
						toast.success('Promo applied!!');
						setPromoObj(result.response);
					}
				} else {
					toast.error(result.message);
				}
			})
			.catch(err => {
				console.log(err);
			})
			.finally(() => {
				setProcessingPromo(false);
			})

	}

	function clearCart() {
		setCookie('cart', '');
		setCourses([]);
		setPromoObj({});
		setPromo('');
	}

	async function onsubmit(event) {

		if (cardValidationError) return;

		setProcessing(true);
		setError('');

		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			setLoading(false);
			return;
		}

		try {
			let res = await _fetch(`${process.env.REACT_APP_API_URL}/payment`, {
				method: "POST",
				body: {
					course_id: courses.map(e => e._id),
					promo: promoObj.name
				}
			});

			let result = await res.json();

			if (res.status === 200) {
				const payment_result = await stripe.confirmCardPayment(result.response, {
					payment_method: {
						card: elements.getElement(CardElement),
						billing_details: {
							name: name,
							email: email
						},
					}
				});

				console.log(payment_result);

				if (payment_result.error) {
					setError(payment_result.error.message);
					toast.error(payment_result.error.message, 5000);
				}
				else {

					if (payment_result.paymentIntent.status === 'succeeded') {
						res = await _fetch(`${process.env.REACT_APP_API_URL}/payment?pi_id=${payment_result.paymentIntent.id}`);
						result = await res.json();
						if (res.status === 200) {
							toast.success(result.response, 5000);
							clearCart();
							navigate('/dashboard');
						} else {
							toast.error('Payment failed, please try again', 5000);
							setError('Payment failed, please try again');
						}
					}
				}
			} else {
				toast.error(result.response, 5000);
				setError(result.response);
			}
		} catch (err) {

			console.log(err);
			if (err.response)
				return toast.error(err.response.data);
			toast.error("Some error occurred while processing payment please try again");
		} finally {
			setProcessing(false);
		}

		return false;

	}


	if (loading) {
		return <Loader />
	}

	return (
		<div className="container py-4">
			<Row gutter={[16, 16]}>
				<Col xs={24} md={{ span: 12, offset: 6 }}>
					<Card>
						<h4 className="mb-4">My Cart</h4>
						{
							courses.length ?
								<React.Fragment>
									<h6 className="mb-2">Courses</h6>
									{
										courses.map((e, i) => (
											<Row className="mb-4" gutter={16} key={`c${i}`}>
												<Col span={16}>{i + 1}. <span className="ps-2">{e.name}</span></Col>
												<Col span={8} className="d-flex">
													£{e.price.toFixed(2)}
													<CloseOutlined
														onClick={() => {

															if (e._id === promoObj.course_id?._id) {
																setPromoObj({});
															}

															console.log(cookies.cart.split(','), e._id);
															setCookie('cart', cookies.cart.split(',').filter(c => c !== e._id).join(','));
															setCourses(update(courses, { $splice: [[i, 1]] }));
														}}
														className='ms-auto'
													/>
												</Col>
											</Row>
										))
									}

									{
										promoObj.discount
											?
											<React.Fragment>
												<Row className="mb-4" gutter={16}>
													<Col span={16}>
														<h6 className="mb-1">Promo ({promoObj.name})</h6>
														<p style={{ fontSize: 10 }} className="mb-0">
															{promoObj.course_id?.name ? `Applicable only on ${promoObj.course_id?.name} course` : ''}
														</p>
													</Col>
													<Col span={8} className="d-flex">
														{promoObj.discount}% off
														<CloseOutlined
															onClick={() => {
																setPromo('');
																setPromoObj({});
															}}
															className='ms-auto'
														/>
													</Col>
												</Row>
											</React.Fragment>
											:
											null
									}

									<Row className="mb-4" gutter={16}>
										<Col span={16}>
											<h6 className="mb-0">Tax (VAT @20%)</h6>
										</Col>
										<Col span={8}>
											£{
												promoObj.name ?
													promoObj.course_id?._id
														?
														Number(
															courses
																.reduce((val, e) => {
																	if (e._id === promoObj.course_id?._id) {
																		return val + (e.price * (1 - (promoObj.discount / 100)))
																	}
																	return val + e.price
																},
																	0) * 0.2
														)
															.toFixed(2)
														:
														Number(courses.reduce((val, e) => val + e.price, 0) * (1 - (promoObj.discount) / 100) * 0.2).toFixed(2)
													:
													Number(courses.reduce((val, e) => val + e.price, 0) * 0.2).toFixed(2)
											}
										</Col>
									</Row>

									<Row className="mb-4" gutter={16}>
										<Col span={16}>
											<h6 className="mb-0">Total</h6>
										</Col>
										<Col span={8}>
											£{
												promoObj.name ?
													promoObj.course_id?._id
														?
														Number(
															courses
																.reduce((val, e) => {
																	if (e._id === promoObj.course_id?._id) {
																		return val + (e.price * (1 - (promoObj.discount / 100)))
																	}
																	return val + e.price
																},
																	0) * 1.2
														)
															.toFixed(2)
														:
														Number(courses.reduce((val, e) => val + e.price, 0) * (1 - (promoObj.discount) / 100) * 1.2).toFixed(2)
													:
													Number(courses.reduce((val, e) => val + e.price, 0) * 1.2).toFixed(2)
											}
										</Col>
									</Row>

									<h6 className="mb-2">
										Promo Code
									</h6>
									<Row className="mb-4" gutter={16}>
										<Col span={16}>
											<Input onChange={(ev) => setPromo(ev.target.value)} className="w-100 me-2" />
										</Col>
										<Col span={8}>
											<Button loading={processingPromo} className="w-100" onClick={applyPromo}>
												Apply Promo
											</Button>
										</Col>
									</Row>

									<h6 className="mb-2">
										Card Details
									</h6>
									<div className="mb-4">
										<CardSection />
									</div>

									{(error || cardValidationError) && <p className='text-danger mb-0'>{(error || cardValidationError)}</p>}


									<div className="d-flex justify-content-end mt-5">
										<Button
											disabled={processing} type="link" htmlType="button"
											onClick={clearCart}
										>
											Clear Cart
										</Button>
										<Button loading={processing} onClick={onsubmit} type="primary" htmlType="button">
											Buy Now
										</Button>
									</div>

								</React.Fragment>
								:
								<React.Fragment>
									<h6>Your Cart is empty visit your <Link to='/dashboard'>dashboard</Link> to buy courses now.</h6>
								</React.Fragment>
						}
					</Card>
				</Col>
			</Row>
		</div >
	)
}