import { Button, Checkbox, Divider, Modal, Tabs } from "antd";
import React, { useState } from "react";
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';

import './styles.less';

const { TabPane } = Tabs;

export const CookiePopUp = () => {

    const [cookie, setCookie] = useCookies(['cookie_permission']);
    const [isOpen, setIsOpen] = useState(false);
    const [allowedPermissions, setAllowedPermissions] = useState(['marketing', 'analytical']);

    const Actions = () => (
        <React.Fragment>
            <Divider className="mt-5 mb-3" />
            <div className="d-flex">
                <Button
                    className="me-2" type="primary"
                    onClick={() => {
                        setCookie('cookie_permission', 'marketing,analytical');
                        setIsOpen(false);
                    }}
                >
                    Enable All
                </Button>
                <Button
                    onClick={() => {
                        setCookie('cookie_permission', allowedPermissions.join(','))
                    }}
                >
                    Save Settings
                </Button>
            </div>

        </React.Fragment>
    )

    if (cookie.cookie_permission) {
        return null;
    }

    return (
        <React.Fragment>
            <div className="cookie-popup">
                <p>This website uses cookies to provide you with a great user experience. For more information please view our <Link to='/privacy'>Privacy Policy</Link>.</p>
                <div className="d-flex justify-content-end">
                    <Button
                        type='primary' className="me-2"
                        onClick={() => setCookie('cookie_permission' ,'marketing,analytical')}
                    >
                        Ok
                    </Button>
                    <Button
                        onClick={() => setIsOpen(true)}
                    >
                        Settings
                    </Button>
                </div>
            </div>
            <Modal visible={isOpen} onCancel={() => setIsOpen(false)} footer={null}>

                <Tabs defaultActiveKey="marketing" tabPosition='left'>
                    
                    <TabPane tab="Privacy Overview" key='overview'>
                        <div className="d-flex flex-column">
                            <h4>PRIVACY OVERVIEW</h4>
                            <p>
                                This website uses cookies so that we can provide you with the best user experience possible. Cookie information is stored in your browser and performs functions such as recognising you when you return to our website and helping our team to understand which sections of the website you find most interesting and useful.
                            </p>
                            <Actions />
                        </div>
                    </TabPane>

                    <TabPane tab="Functional Cookies" key='functional'>
                        <div className="d-flex flex-column">
                            <h4>FUNCTIONAL COOKIES</h4>
                            <p>
                                Several cookies are required for the site to function (such as this acceptance form). These enhance the experience of using the site by remembering certain actions and allowing core functionality.
                            </p>
                            <Checkbox checked={true} disabled={true} />
                            <Actions />
                        </div>
                    </TabPane>

                    <TabPane tab="Marketing Cookies" key='marketing'>
                        <div className="d-flex flex-column">
                            <h4>MARKETING COOKIES</h4>
                            <p>
                                This website uses third party marketing cookies to collect anonymous information about your browsing habits.
                            </p>
                            <Checkbox checked={allowedPermissions.includes('marketing')}
                                onChange={(ev) => {
                                    let ind = allowedPermissions.findIndex(e => e === 'marketing');
                                    if (ev.target.checked) {
                                        if (ind > -1) return;
                                        setAllowedPermissions([...allowedPermissions, 'marketing'])
                                    } else {
                                        if (ind === -1) return;
                                        if (allowedPermissions.length > 1) {
                                            setAllowedPermissions(['analytical']);
                                        } else {
                                            setAllowedPermissions([])
                                        }
                                    }
                                }}
                            />
                            <Actions />
                        </div>
                    </TabPane>

                    <TabPane tab="Analytical Cookies" key='analytical'>
                        <div className="d-flex flex-column">
                            <h4>ANALYTICAL COOKIES</h4>
                            <p>
                                This website uses third party tracking cookies such as Google Analytics to collect anonymous information such as the number of visitors to the site, and the most popular pages.
                            </p>
                            <Checkbox checked={allowedPermissions.includes('analytical')}
                                onChange={(ev) => {
                                    let ind = allowedPermissions.findIndex(e => e === 'analytical');
                                    if (ev.target.checked) {
                                        if (ind > -1) return;
                                        setAllowedPermissions([...allowedPermissions, 'analytical'])
                                    } else {
                                        if (ind === -1) return;
                                        if (allowedPermissions.length > 1) {
                                            setAllowedPermissions(['marketing']);
                                        } else {
                                            setAllowedPermissions([])
                                        }
                                    }
                                }}
                            />
                            <Actions />
                        </div>
                    </TabPane>

                    <TabPane tab="Privacy Policy" key='privacy'>
                        <div className="d-flex flex-column">
                            <h4>PRIVACY POLICY</h4>
                            <p>
                                Please find the <Link to='/privacy'>Privacy Policy</Link> for Unitive Technologies Limited to understand our views and practices regarding Your Data and how we will treat it.
                            </p>
                            <Actions />
                        </div>
                    </TabPane>
                </Tabs>

            </Modal>
        </React.Fragment>
    );

}