import { Button, DatePicker, Input, Radio, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { GiMoneyStack, GiTakeMyMoney } from 'react-icons/gi';
import { MdMoneyOff } from 'react-icons/md';
import { toast } from 'react-toastify';
import useFetch from '../../_fetch';
import Placements from '../Placements';
import Testimonial from '../testimonial';

import "./styles.less";

const { Option } = Select;

export function FundedTraining() {

	const [form, setForm] = useState({
		first_name: '',
		last_name: '',
		email: '',
		mobile: '',
		country: '',
		gender: '',
		batch: '',
		payment_mode: '',
		date_of_birth: '',
		employment_history: '',
		last_annual_salary: 0,
		last_employment_role: '',
		_last_employment_role: '',
		years_of_experience: 0
	});
	const [success, setSuccess] = useState("");
	const [err, setErr] = useState("");
	const [loading, setLoading] = useState(false);

	let _fetch = useFetch();

	const onClick = (ev) => {
		ev.preventDefault();
		let emailRegex = /^[^\s()<>@,;:/]+@\w[\w.-]+\.[a-z]{2,}$/i;

		setErr("");
		setSuccess("");

		const {
			first_name, last_name, mobile, email, country, gender, batch, payment_mode, date_of_birth,
			employment_history, last_annual_salary, last_employment_role, _last_employment_role, years_of_experience
		} = form;

		if (email.length === 0 && !emailRegex.test(email))
			return setErr("Please enter a Email Id");
		else if (first_name.length < 3)
			return setErr("Name should be at least 3 character long");
		else if (mobile.length === 0)
			return setErr("mobile number cannot be empty");
		else if (country.length === 0)
			return setErr("country cannot be empty");
		else if (gender.length === 0)
			setErr("please choose a gender");
		else if (batch.length === 0)
			return setErr("please select a timing for classes");
		else if (payment_mode.length === 0)
			return setErr("please select a payment mode");
		else if (date_of_birth.length === 0)
			return setErr("D.O.B cannot be empty");
		else if (!employment_history)
			return setErr("employment history cannot be empty");
		else if (employment_history === 'Yes') {
			if (!last_annual_salary) return setErr("last annual salary cannot be empty");
			if (!years_of_experience) return setErr("years of experience cannot be empty");
			else if (!last_employment_role) return setErr("last employment role cannot be empty");
			else if (last_employment_role === 'Other' && !_last_employment_role) return setErr("last employment role cannot be empty");
		}
		setLoading(true)
		_fetch(`${process.env.REACT_APP_API_URL}/user/free_training`, {
			method: "POST",
			body: {
				first_name, last_name, mobile, email, country, gender, batch,
				payment_mode, date_of_birth, employment_history, last_annual_salary,
				last_employment_role, _last_employment_role, years_of_experience
			}
		})
			.then(res => {
				toast.success("Thank you for showing interest we will get back to you shortly.", {
					autoClose: 5000
				});
				setForm({
					first_name: '',
					last_name: '',
					email: '',
					mobile: '',
					country: '',
					gender: '',
					batch: '',
					payment_mode: '',
					date_of_birth: '',
					employment_history: '',
					last_annual_salary: 0,
					last_employment_role: '',
					_last_employment_role: '',
					years_of_experience: 0
				});
			})
			.catch(err => {
				console.log(err);
				if (err.response)
					toast.error('You are offline, please try again');
			})
			.finally(() => setLoading(false))
	}

	useEffect(() => {
		if (err)
			toast.error(err, { autoClose: 5000 })
	}, [err]);

	const onChange = (ev) => {
		setForm({
			...form,
			[ev.target.name]: ev.target.value
		})
	}

	const onSelectChange = (name) => (val) => setForm({ ...form, [name]: val });

	return (
		<main id="funded-training">

			<section id="about" className="about">

				<div className="section-title">
					<h2>Future Earnings Agreement</h2>
					<p>Proper Training now enables you to pay for your course with a share of your future earnings</p>
				</div>

				<div className="container-fluid px-0">

					<div className="row w-100">
						<div className="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch position-relative">
							{/* <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="glightbox play-btn mb-4"></a> */}
							<img className="img img-fluid" alt="..." src="/img/fea.jpg" />
						</div>

						<div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 ps-4 px-lg-5">
							<h3 className='text-dark'>
								Instead of an upfront fee you can repay 12.5% of your earnings for 36 months
							</h3>
							{/* <p>Esse voluptas cumque vel exercitationem. Reiciendis est hic accusamus. Non ipsam et sed minima temporibus laudantium. Soluta voluptate sed facere corporis dolores excepturi. Libero laboriosam sint et id nulla tenetur. Suscipit aut voluptate.</p> */}

							<div className="icon-box d-flex align-items-center">
								<div className="icon"><i><GiMoneyStack size={30} /></i></div>
								<h4 className="title">Only repay when you earn over £25,000 per year</h4>
							</div>

							<div className="icon-box d-flex align-items-center">
								<div className="icon"><i><GiTakeMyMoney size={30} className="" /></i></div>
								<h4 className="title">Never repay more than £18,000</h4>
							</div>

							<div className="icon-box d-flex align-items-center">
								<div className="icon"><i><MdMoneyOff size={30} /></i></div>
								<h4 className="title">Any balance remaining after 8 years is waived</h4>
							</div>

						</div>

						<div className='col-12 col-lg-8 offset-lg-2 mt-5'>
							<h3 className='fw-light text-center'>
								You are protected. Regulated and approved by Financial Conduct Authority.
							</h3>
						</div>

					</div>

				</div>
			</section>

			<section className="section-bg">
				<div className="container">

					<div className="section-title">
						<h2>Enquire Now</h2>
						<p>Fill the following form, we will contact you back.</p>
					</div>

					<div className='row'>
						<form className="col-12 col-lg-6 offset-lg-3" onSubmit={onClick}>

							<div className="text-primary mb-1 col-12 text-center">{success}</div>
							<div className="text-danger mb-1 col-12 text-center">{err}</div>

							<div className="form-group">
								<label htmlFor="first_name">First Name</label>
								<Input type="text" name="first_name"
									onChange={onChange} value={form.first_name}
								/>
							</div>

							<div className="form-group">
								<label htmlFor="last_name">Last Name</label>
								<Input type="text" name="last_name"
									onChange={onChange} value={form.last_name}
								/>
							</div>

							<div className="form-group">
								<label htmlFor="email">Email</label>
								<Input type="text" name="email"
									onChange={onChange} value={form.email}
								/>
							</div>

							<div className="form-group">
								<label htmlFor="mobile">Mobile Number (with country code)</label>
								<Input type="text" name="mobile"
									onChange={onChange} value={form.mobile}
								/>
							</div>

							<div className="form-group">
								<label>Date Of Birth</label>
								<DatePicker className="w-100" format='DD/MM/YYYY'
									value={form.date_of_birth} onChange={onSelectChange('date_of_birth')} />
							</div>

							<div className="form-group">
								<label>Which country passport do you hold</label>
								<Select defaultValue="" className="select_box form-control border-0 p-0"
									value={form.country} onChange={onSelectChange('country')}>
									<Option value="" disabled></Option>
									<Option value='British'>British</Option>
									<Option value='Indefinite Leave to Remain (Britain)'>Indefinite Leave to Remain (Britain)</Option>
									<Option value='EU Passport'>EU Passport</Option>
									<Option value='Other'>Other</Option>
								</Select>
							</div>

							<div className="form-group">
								<label>Which day and timings are suitable for your training?</label>
								<Select defaultValue="" className="select_box form-control border-0 p-0"
									value={form.batch} onChange={onSelectChange('batch')}>
									<Option value="" disabled></Option>
									<Option value="Day time 9AM-3PM (Monday-Friday)">
										Day time 9AM-3PM (Monday-Friday)
									</Option>
									<Option value="Evening 6-9PM(Monday-Friday)">
										Evening 6-9PM(Monday-Friday)
									</Option>
									<Option value="Weekends 7AM-9PM (Saturday,Sunday)">
										Weekends 7AM-9PM (Saturday,Sunday)
									</Option>
								</Select>
							</div>

							<div className="form-group">
								<label>What gender category do you identify with?</label>
								<Select defaultValue="" className="select_box form-control border-0 p-0"
									value={form.gender} onChange={onSelectChange('gender')}>
									<Option value="" disabled></Option>
									<Option value="Male">
										Male
									</Option>
									<Option value="Female">
										Female
									</Option>
									<Option value="Prefer not to say">
										Prefer not to say
									</Option>
								</Select>
							</div>

							<div className="form-group">
								<label>How do you wish to pay for your training?</label>
								<Select defaultValue="" className="select_box form-control border-0 p-0"
									value={form.payment_mode} onChange={onSelectChange('payment_mode')}>
									<Option value="" disabled></Option>
									<Option value="Future Earnings Agreement (12.5% earnings over 36 months)">
										Future Earnings Agreement (12.5% earnings over 36 months)
									</Option>
									<Option value="Pay upfront (£9345)">
										Pay upfront (£9345)
									</Option>
									<Option value="Pay in installments (£9345)">
										Pay in installments (£9345)
									</Option>
								</Select>
							</div>

							<div className="form-group">
								<label htmlFor="employment_history">Do you have any employment history?</label><br />
								<Radio.Group
									value={form.employment_history} name='employment_history'
									onChange={onChange}
								>
									<Radio value="Yes">Yes</Radio>
									<Radio value="No">No</Radio>
								</Radio.Group>
							</div>

							{
								form.employment_history === 'Yes' &&
								<React.Fragment>

									<div className="form-group">
										<label htmlFor="last_annual_salary">Last Annual salary</label>
										<input type='number' className="form-control" name="last_annual_salary"
											onChange={onChange} value={form.employment_history === 'Yes' ? form.last_annual_salary : ''}
										/>
									</div>

									<div className="form-group">
										<label htmlFor="years_of_experience">Years of previous experience</label>
										<input type='number' className="form-control" name="years_of_experience"
											onChange={onChange} value={form.employment_history === 'Yes' ? form.years_of_experience : ''}
										/>
									</div>

									<div className="form-group">
										<label>Last Employment role/Area</label>
										<Select defaultValue="" className="select_box form-control border-0 p-0"
											value={form.employment_history === 'Yes' ? form.last_employment_role : ''} onChange={onSelectChange('last_employment_role')}>
											<Option value="" disabled></Option>
											{
												[
													"Finance", "Marketing", "Product", "Operations", "Sales", "Law", "Engineering", "Analytics", "General Administration",
													"IT (Information Technology)", "HR (Human Resources)", "Senior Management", "Other"
												]
													.map((e, i) =>
														<Option key={i} value={e}>{e}</Option>)
											}
										</Select>
									</div>

									{
										form.last_employment_role === 'Other' &&
										<div className="form-group">
											<label htmlFor="_last_employment_role">Employment Role</label>
											<input type='text' className="form-control" name="_last_employment_role"
												onChange={onChange} value={form.employment_history === 'Yes' ? form._last_employment_role : ''}
											/>
										</div>
									}

								</React.Fragment>
							}

							<div
								id="k-paywith-action"
								k-custom="green"
								k-provider-id="0014J00000ZAcDgQAL"
								k-course-id="ISGRC01"
								className={form.payment_mode === 'Pay in installments (£9345)' ? 'd-block' : 'd-none'}
							>
							</div>
							<Button
								type="primary"
								htmlType="submit"
								loading={loading}
								onClick={onClick}
								onSubmit={onClick}
								className={form.payment_mode === 'Pay in installments (£9345)' ? 'd-none' : 'd-block'}
							>
								Submit
							</Button>
						</form>
					</div>

				</div>
			</section>

			<section id="placements" className="placements">
				<div className="section-title">
					<h2>Placements</h2>
					<p>
						Our students got placed into below reputable companies:
					</p>
				</div>

				<div className="container">
					<Placements />
				</div>
			</section>

			<section id="testimonials" className="testimonials section-bg">


				<div className="section-title">
					<h2>Testimonials</h2>
				</div>

				<div className="container">
					<Testimonial />
				</div>
			</section>

		</main>
	);
}
