import { LockOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Input, Row } from 'antd';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import useFetch from '../../_fetch';
import './styles.less';
import { toast } from 'react-toastify';

export function ResetPassword() {

	const [form, setForm] = useState({
		password: '',
		confirmPassword: ''
	});
	const [loading, setLoading] = useState(false);

	let _fetch = useFetch();
	let location = useLocation();

	const _login = async () => {

		if(form.password !== form.confirmPassword) return toast.error('Password and confirm password do not match');
        
		if (!form.password) {
            toast.error('Please enter your password');
            return;
        }

		try {
			setLoading(true);
			let res = await _fetch(
				`${process.env.REACT_APP_API_URL}/user/forgot_password`,
				{
					method: "PATCH",
					body: { password: form.password, token: new URLSearchParams(location.search).get('tok') }
				});
			let result = await res.json();
			if (res.status === 200) {
				setForm({});
				return toast.success('Password reset successfully!');
			} else {
				toast.error(result.response);
			}
		} catch (err) {
			console.log(err);
			toast.error('You are offline, please check your network connection');
		} finally {
			setLoading(false);
		}
	}

	return (
		<div className='container h-100 d-flex flex-column'>
			<Row justify='center' className='my-5'>
				<Col>
					<Card>
						<h3 className='mb-0'>Reset Your Password</h3>
						<Divider className='my-4' />
						<p className='mb-3'>Please enter your new password to recover your account.</p>
						<Input
							placeholder='Enter new password' prefix={<LockOutlined />}
							className='login-mb-40'
							onChange={(ev) => setForm({ ...form, password: ev.target.value })}
							value={form.password} type="password"
						/>
						<Input
							placeholder='Renter new password' prefix={<LockOutlined />}
							className='login-mb-40'
							onChange={(ev) => setForm({ ...form, confirmPassword: ev.target.value })}
							value={form.confirmPassword} type="password"
						/>
						<div className='d-flex'>
							<Button
								type='primary' disabled={loading}
								loading={loading}
								onClick={_login}
								className='text-center'
							>
								Reset Password
							</Button>
						</div>
						<Divider className='my-4' />
						<Row gutter={8}>
							<Col span={12} className='text-start align-middle'>
								<Link to='/login'>Login to your account</Link>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
		</div>
	)

}