import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Input, Divider, Row, Card } from 'antd';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import { FcGoogle } from 'react-icons/fc';
import { FaFacebook } from 'react-icons/fa';

import useFetch from '../../_fetch';
import { login } from '../../store/action/user';

import './styles.less';

export function Register() {

    const [tncCheck, setTncCheck] = useState(false);
    const [form, setForm] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: ''
    });
    const [loading, setLoading] = useState('');

    let navigate = useNavigate();
    let _fetch = useFetch();
    let dispatch = useDispatch();

    const register = async () => {
        if (!tncCheck) {
            toast.error('Please accept terms and conditions');
            return;
        }
        if (!form.email) {
            toast.error('Please enter your email address');
            return;
        }
        if (form.first_name.length < 3) {
            toast.error('Please enter your first name');
            return;
        }
        if (form.last_name.length < 3) {
            toast.error('Please enter your last name');
            return;
        }
        if (form.password.length < 8) {
            toast.error('Your password should be of minimum 8 character');
            return;
        }
        try {
            setLoading(true);
            let res = await fetch(`${process.env.REACT_APP_API_URL}/user`, {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify({
                    first_name: form.first_name,
                    last_name: form.last_name,
                    email: form.email,
                    password: form.password
                })
            });

            let result = await res.json();
            if (res.status === 200) {
                toast.info('Account created successfully please verify your email to continue');
                setForm({});
                setTncCheck(false);
                navigate('/login');
            } else {
                toast.error(result.response);
            }
        } catch (err) {
            console.log(err);
            toast.error('You are offline, please check your network connection');
        } finally {
            setLoading(false);
        }

    }

    async function facebookLogin(response) {
        console.log(response);
        if (response.accessToken) {
            try {
                let res = await _fetch(process.env.REACT_APP_API_URL + '/user/facebook', { method: "POST", body: { access_token: response.accessToken } })
                let result = await res.json();
                if (res.status === 200) {
                    dispatch(login(result.response));
                    if (result.response.status === 'REGISTERED') {
                        navigate('/user_info');
                    } else {
                        navigate('/dashboard');
                    }
                } else {
                    toast.error(result.response);
                }
            }
            catch (err) {
                console.log(err);
                toast.error('Some error occurred please try again later.')
            } finally {
                setLoading(false);
            }
        } else {
            toast.error('Some error occurred please try again');
        }
    }

    async function googleLogin(response) {
        console.log(response);
        if (response.accessToken) {
            try {
                let res = await _fetch(process.env.REACT_APP_API_URL + '/user/google', { method: "POST", body: { id_token: response.tokenObj.id_token } })
                let result = await res.json();
                if (res.status === 200) {
                    dispatch(login(result.response));
                    if (result.response.status === 'REGISTERED') {
                        navigate('/user_info');
                    } else {
                        navigate('/dashboard');
                    }
                } else {
                    toast.error(result.response);
                }
            } catch (err) {
                console.log(err);
                toast.error('Some error occurred please try again later.')
            } finally {
                setLoading(false);
            }
        }
    }

    function googleLoginError(error) {
        if (error.error === 'idpiframe_initialization_failed') return;
        toast.error('Some error occurred please try again later');
    }

    return (
        <div className='container h-100'>
            <Row justify='center' className='my-5'>
                <Col xs={20} md={14} xl={12}>
                    <Card>
                        <h3 className='mb-0'>Create Your Free Account</h3>
                        <Divider className='my-4' />

                        <Row className='w-100' gutter={[16, 16]}>
                            <Col xs={24} md={12}>
                                <GoogleLogin
                                    clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
                                    buttonText="Login"
                                    onSuccess={googleLogin}
                                    onFailure={googleLoginError}
                                    cookiePolicy={'single_host_origin'}
                                    scopes='profile'
                                    size='lg'
                                    render={renderProps => (
                                        <Button
                                            className="d-flex align-items-center justify-content-center w-100"
                                            type="default"
                                            onClick={renderProps.onClick}
                                            icon={<FcGoogle size={25} className='pe-2' />}
                                        >
                                            Sign up With Google
                                        </Button>
                                    )}
                                    autoLoad={false}
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <FacebookLogin
                                    appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                                    callback={facebookLogin}
                                    render={renderProps => (
                                        <Button
                                            className="d-flex align-items-center justify-content-center w-100"
                                            type="default"
                                            size='lg'
                                            onClick={renderProps.onClick}
                                            icon={<FaFacebook size={25} className='pe-2' color='#4267B2' />}
                                        >
                                            Sign up With Facebook
                                        </Button>
                                    )}
                                    cookie={false}
                                    autoLoad={false}
                                />
                            </Col>
                        </Row>

                        <h6 className='text-center my-3'>OR</h6>

                        <p className='pb-3'>Please enter your details to continue.</p>
                        <Row className='login-mb-40' gutter={[16, 40]}>
                            <Col xs={24} md={12} >
                                <Input
                                    placeholder='Enter your first name' prefix={<UserOutlined />}
                                    className='w-100'
                                    onChange={(ev) => setForm({ ...form, first_name: ev.target.value })}
                                    value={form.first_name}
                                />
                            </Col>
                            <Col xs={24} md={12} >
                                <Input
                                    placeholder='Enter your last name' prefix={<UserOutlined />}
                                    className='w-100'
                                    onChange={(ev) => setForm({ ...form, last_name: ev.target.value })}
                                    value={form.last_name}
                                />
                            </Col>
                        </Row>
                        <Input
                            placeholder='Enter your email ID' prefix={<MailOutlined />}
                            className='login-mb-40' type='email'
                            onChange={(ev) => setForm({ ...form, email: ev.target.value })}
                            value={form.email}
                        />
                        <Input
                            placeholder='Enter password' prefix={<LockOutlined />}
                            className='login-mb-40'
                            onChange={(ev) => setForm({ ...form, password: ev.target.value })}
                            value={form.password} type='password'
                        />

                        <Checkbox className='login-mb-40' onChange={(ev) => setTncCheck(ev.target.checked)} checked={tncCheck}>
                            I agree with <Link to='/tnc' className='text-primary'>terms and conditions</Link>
                        </Checkbox>

                        <div>
                            <Button
                                type='primary' disabled={loading || !tncCheck}
                                loading={loading}
                                onClick={register}
                                className='text-center'
                            >
                                Sign Up
                            </Button>
                        </div>

                        <Divider className='my-4' />

                        <Row gutter={8}>
                            <Col span={12} className='text-start'>Already have an account?</Col>
                            <Col span={12}>
                                <Button onClick={() => navigate('/login')} className='w-100'>
                                    Login
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </div >
    )

}