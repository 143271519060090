import React from 'react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Placements() {

    const images = [
        '/img/Allianz.png',
        '/img/Capgemini.png',
        '/img/Pretty Little Thing.png',
        '/img/KPMG.png',
        '/img/MUFG Bank.png',
        '/img/Oxford University Press.png',
        '/img/Sainsbury.png'
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 10,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true
    };

    return (
        <div className='row justify-content-center testimonial'>
            <div className="col-lg-12">
                <Slider {...settings}>
                    {
                        images.map((e, i) => (
                            <div className='w-100 h-100 d-flex px-4' key={`placement-${i}`}>
                                <img src={e} className='img img-fluid my-auto' alt='..' />
                            </div>
                        ))
                    }
                </Slider>
            </div>
        </div>
    )
}