import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { GrCertificate, GrUserExpert } from 'react-icons/gr';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { MdWorkOutline } from 'react-icons/md';

import "./styles.less";
import Testimonial from "../testimonial";
import Placements from "../Placements";
import Partners from "../Partners";
import useFetch from '../../_fetch';
import { CourseCard } from "./courseCard";
import { Row } from "antd";

export function Home() {

	let _fetch = useFetch();
	let { _id } = useSelector(st => st.user);
	let location = useLocation();
	const [courses, setCourses] = useState([]);

	const aboutRef = useRef();
	const testimonialRef = useRef();
	const coursesRef = useRef();

	useEffect(() => {
		async function init() {
			try {
				let res = await _fetch(`${process.env.REACT_APP_API_URL}/course/all`);
				let result = await res.json();
				if (res.status === 200) {
					setCourses(result.response);
				}
			} catch (e) {
				console.log(e);
			}
		}

		init();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (location.hash) {
			switch (location.hash.slice(1)) {
				case 'about': {
					if (aboutRef.current) {
						window.scrollTo(0, aboutRef.current.offsetTop - 50)
					}
					break;
				}
				case 'testimonial': {
					if (testimonialRef.current) {
						window.scrollTo(0, testimonialRef.current.offsetTop - 50)
					}
					break;
				}
				case 'courses': {
					if (coursesRef.current) {
						window.scrollTo(0, coursesRef.current.offsetTop - 50)
					}
					break;
				}
				default: {
					break;
				}
			}
		}
	}, [location]);

	return (
		<div id="home">
			<section id="hero" className="d-flex align-items-center">
				<div className="container">
					<h1>Welcome to Proper Trainings</h1>
					<h2>Learn Cyber Security from Experts and become DevSecops Engineer,Cloud &amp; Container Security Architect,Information security specialists.</h2>
					<Link to={_id ? '/dashboard' : '/register'} className="btn-get-started-2 me-3">
						Start Free Trial
					</Link>
					<Link to={_id ? '/dashboard' : '/login'} className="btn-get-started">
						Get Started
					</Link>
				</div>
			</section>

			<main id="main">

				<section id="why-us" className="why-us">
					<div className="container">

						<div className="row">
							<div className="col-lg-4 d-flex align-items-stretch">
								<div className="content">
									<h3>Why choose Proper Training for my education?</h3>
									<p>
										Proper Training have experienced,competent trainers who have over a decade of experience. Our trainers have average industry experience of 15 years.Our training has helped more than 1000+ students till now to land a job.
									</p>
									<div className="text-center">
										<Link to={_id ? '/dashboard' : '/login'} className="more-btn">Start Your Career <i className="bx bx-chevron-right"></i></Link>
									</div>
								</div>
							</div>
							<div className="col-lg-8 d-flex align-items-stretch">
								<div className="icon-boxes d-flex flex-column justify-content-center">
									<div className="row">
										<div className="col-xl-4 d-flex align-items-stretch">
											<div className="icon-box mt-4 mt-xl-0">
												<i className="bx bx-receipt"></i>
												<h4>Role Based Training</h4>
												<p>We provide role based training and personalize it to get a job.</p>
											</div>
										</div>
										<div className="col-xl-4 d-flex align-items-stretch">
											<div className="icon-box mt-4 mt-xl-0">
												<i className="bx bx-cube-alt"></i>
												<h4>Learning paths:</h4>
												<p>Pick a learning path and become a pro.</p>
											</div>
										</div>
										<div className="col-xl-4 d-flex align-items-stretch">
											<div className="icon-box mt-4 mt-xl-0">
												<i className="bx bx-images"></i>
												<h4>Courses</h4>
												<p>Every course comes with multiple hours of videos and lab exercise.</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</section>

				<section id="about" ref={aboutRef} className="about">
					<div className="container-fluid px-0">

						<div className="row w-100">
							<div className="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch position-relative">
								{/* <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="glightbox play-btn mb-4"></a> */}
								<img className="img img-fluid" alt="..." src="job.jpg" />
							</div>

							<div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 ps-4 px-lg-5">
								<h3>Get Ready for your dream job with proper training</h3>
								{/* <p>Esse voluptas cumque vel exercitationem. Reiciendis est hic accusamus. Non ipsam et sed minima temporibus laudantium. Soluta voluptate sed facere corporis dolores excepturi. Libero laboriosam sint et id nulla tenetur. Suscipit aut voluptate.</p> */}

								<div className="icon-box d-flex align-items-center">
									<div className="icon"><i><GrUserExpert className="ps-1" /></i></div>
									<h4 className="title"><Link to={_id ? '/dashboard' : '/'}>Take Expert Lead training courses.</Link></h4>
									{/* <p className="description">Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p> */}
								</div>

								<div className="icon-box d-flex align-items-center">
									<div className="icon"><i><GrCertificate /> </i></div>
									<h4 className="title"><Link to={_id ? '/dashboard' : '/'}>Earn training certificates.</Link></h4>
									{/* <p className="description">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque</p> */}
								</div>

								<div className="icon-box d-flex align-items-center">
									<div className="icon"><i><AiOutlineFilePdf /></i></div>
									<h4 className="title"><Link to={_id ? '/dashboard' : '/'}>Get a resume created by professionals.</Link></h4>
									{/* <p className="description">Explicabo est voluptatum asperiores consequatur magnam. Et veritatis odit. Sunt aut deserunt minus aut eligendi omnis</p> */}
								</div>

								<div className="icon-box d-flex align-items-center">
									<div className="icon"><i><MdWorkOutline /></i></div>
									<h4 className="title"><Link to={_id ? '/dashboard' : '/'}>Find new job opportunities.</Link></h4>
									{/* <p className="description">Explicabo est voluptatum asperiores consequatur magnam. Et veritatis odit. Sunt aut deserunt minus aut eligendi omnis</p> */}
								</div>

							</div>
						</div>

					</div>
				</section>

				<section ref={coursesRef} id='courses' className="section-bg">
					<div className="container">

						<div className="section-title">
							<h2>Our Courses</h2>
						</div>

						<Row gutter={[32, 16]}>
							{
								courses.map((e, i) => (
									<CourseCard
										key={e._id}
										course={e}
									/>
								))
							}
						</Row>

					</div>
				</section>

				<section id="placements" className="placements">
					<div className="section-title">
						<h2>Placements</h2>
						<p>
							Our students got placed into below reputable companies:
						</p>
					</div>

					<div className="container">
						<Placements />
					</div>
				</section>

				<section id="testimonials" ref={testimonialRef} className="testimonials section-bg">


					<div className="section-title">
						<h2>Testimonials</h2>
					</div>

					<div className="container">
						<Testimonial />
					</div>
				</section>

				<section id="partners" className="partners">
					<div className="section-title">
						<h2>Our Partners</h2>
						<p>
							Our students got placed into below reputable companies:
						</p>
					</div>

					<div className="container">
						<Partners />
					</div>
				</section>

			</main>
		</div>
	);
}
