import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Input, Row } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import { FcGoogle } from 'react-icons/fc';
import { FaFacebook } from 'react-icons/fa';

import useFetch from '../../_fetch';
import { login } from '../../store/action/user';
import './styles.less';

export function Login() {

    const [form, setForm] = useState({
        email: '',
        password: ''
    });
    const [loading, setLoading] = useState(false);

    let _fetch = useFetch();
    let dispatch = useDispatch();
    let navigate = useNavigate();

    const _login = async () => {
        if (!form.email) {
            toast.error('Please enter your email');
            return;
        }
        if (!form.password) {
            toast.error('Please enter your password');
            return;
        }
        try {
            setLoading(true);
            let res = await _fetch(`${process.env.REACT_APP_API_URL}/user/login`, {
                method: 'POST',
                body: {
                    email: form.email,
                    password: form.password
                }
            });
            let result = await res.json();
            if (res.status === 200) {
                if (!result.response.email_verified)
                    return toast.error('Please verify you email to continue');
                dispatch(login(result.response));
                setForm({});

                if (result.response.status === 'REGISTERED') {
                    navigate('/user_info');
                } else {
                    navigate('/dashboard');
                }
            } else {
                toast.error(result.response);
            }
        } catch (err) {
            console.log(err);
            toast.error('You are offline, please check your network connection');
        } finally {
            setLoading(false);
        }
    }

    async function facebookLogin(response) {
        console.log(response);
        if (response.accessToken) {
            try {
                let res = await _fetch(process.env.REACT_APP_API_URL + '/user/facebook', { method: "POST", body: { access_token: response.accessToken } })
                let result = await res.json();
                if (res.status === 200) {
                    dispatch(login(result.response));
                    if (result.response.status === 'REGISTERED') {
                        navigate('/user_info');
                    } else {
                        navigate('/dashboard');
                    }
                } else {
                    toast.error(result.response);
                }
            }
            catch (err) {
                console.log(err);
                toast.error('Some error occurred please try again later.')
            } finally {
                setLoading(false);
            }
        } else {
            toast.error('Some error occurred please try again');
        }
    }

    async function googleLogin(response) {
        console.log(response);
        if (response.accessToken) {
            try {
                let res = await _fetch(process.env.REACT_APP_API_URL + '/user/google', { method: "POST", body: { id_token: response.tokenObj.id_token } })
                let result = await res.json();
                if (res.status === 200) {
                    dispatch(login(result.response));
                    if (result.response.status === 'REGISTERED') {
                        navigate('/user_info');
                    } else {
                        navigate('/dashboard');
                    }
                } else {
                    toast.error(result.response);
                }
            } catch (err) {
                console.log(err);
                toast.error('Some error occurred please try again later.')
            } finally {
                setLoading(false);
            }
        }
    }

    function googleLoginError(error) {
        console.log(error);
        if (error.error === 'idpiframe_initialization_failed') return;
        toast.error('Some error occurred please try again later');
    }

    return (
        <div className='container h-100 d-flex flex-column'>
            <Row justify='center' className='my-5'>
                <Col>
                    <Card>
                        <h3 className='mb-0'>Login To Your Dashboard</h3>
                        
                        <Divider className='my-4' />

                        <Row className='w-100' gutter={[16, 16]}>
                            <Col xs={24} md={12}>
                                <GoogleLogin
                                    clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
                                    buttonText="Login"
                                    onSuccess={googleLogin}
                                    onFailure={googleLoginError}
                                    cookiePolicy={'single_host_origin'}
                                    scopes='profile'
                                    size='lg'
                                    render={renderProps => (
                                        <Button
                                            className="d-flex align-items-center justify-content-center w-100"
                                            type="default"
                                            onClick={renderProps.onClick}
                                            icon={<FcGoogle size={25} className='pe-2' />}
                                        >
                                            Login With Google
                                        </Button>
                                    )}
                                    autoLoad={false}
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <FacebookLogin
                                    appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                                    callback={facebookLogin}
                                    render={renderProps => (
                                        <Button
                                            className="d-flex align-items-center justify-content-center w-100"
                                            type="default"
                                            size='lg'
                                            onClick={renderProps.onClick}
                                            icon={<FaFacebook size={25} className='pe-2' color='#4267B2'/>}
                                        >
                                            Login With Facebook
                                        </Button>
                                    )}
                                    cookie={false}
                                    autoLoad={false}
                                />
                            </Col>
                        </Row>

                        <h6 className='text-center my-3'>OR</h6>

                        <p className='mb-3'>Please enter your details to continue.</p>
                        <Input
                            placeholder='Enter Your Email' prefix={<MailOutlined />}
                            className='login-mb-40'
                            onChange={(ev) => setForm({ ...form, email: ev.target.value })}
                            value={form.email} type="email"
                        />
                        <Input
                            placeholder='Enter Your Password' prefix={<LockOutlined />}
                            className='login-mb-40'
                            onChange={(ev) => setForm({ ...form, password: ev.target.value })}
                            value={form.password} type='password'
                        />
                        <p className='login-mb-40'>
                            <Link to='/forgot_password'>Forgot password?</Link>
                        </p>
                        <div className='d-flex'>
                            <Button
                                type='primary' disabled={loading}
                                loading={loading}
                                // onClick={otpSent ? onSubmit : sendOTP}
                                onClick={_login}
                                className='text-center w-25'
                            >
                                Login
                            </Button>
                        </div>

                        <Divider className='my-5' />
                        <Row gutter={8}>
                            <Col span={12} className='text-start align-middle'>
                                Don't have an account?
                            </Col>
                            <Col span={12}>
                                <Button onClick={() => navigate('/register')} className='w-100'>
                                    Sign Up
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </div>
    )

}