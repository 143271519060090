import { Button, Card, Col, Input, Row, Typography, List, Form, DatePicker, Select } from "antd";
import { useEffect, useState } from "react";
import useFetch from "../../_fetch";

import { DownloadOutlined, EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import moment from "moment";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const { Option } = Select;

export default function Profile() {

	const { Text } = Typography;
	const [password, setPassword] = useState("");
	const [confirm_password, setConfirmPassword] = useState("");
	const [current_password, setCurrentPassword] = useState("");
	const [error, setError] = useState("");
	const [passwordSuccess, setPasswordSuccess] = useState(false);
	const [courses, setCourses] = useState([]);
	const [order, setOrder] = useState([]);

	const {
		first_name, last_name, email, organization_name, dob, role, gender, nationality
	} = useSelector(st => st.user);

	let _fetch = useFetch();

	useEffect(() => {

		_fetch(`${process.env.REACT_APP_API_URL}/course?only_enrolled=true`)
			.then(async (res) => {
				if (res.status === 200) {
					let result = await res.json();
					setCourses(result.response?.inprogress ?? []);
				} else {
				}
			})
			.catch((err) => {
				console.log(err);
			});

		_fetch(`${process.env.REACT_APP_API_URL}/payment/history`)
			.then(async (res) => {
				let result = await res.json();
				if (res.status === 200) {
					setOrder(result.response ?? []);
				} else {
					toast.error(result.message)
				}
			})
			.catch((err) => {
				console.log(err);
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function handlePasswordSubmit(ev) {
		ev.preventDefault();
		setError("");
		setPasswordSuccess(false);
		if (confirm_password !== password)
			return setError("password and confirm password do not match");
		_fetch(`${process.env.REACT_APP_API_URL}/user/password`, {
			method: "PATCH",
			body: { current_password, password },
		})
			.then(async (res) => {
				let result = await res.json();
				console.log(result.response);
				if (res.status === 200) {
					setPasswordSuccess(true);
				} else {
					setError(result.response);
				}
			})
			.catch((err) => {
				setError(
					"Some error occurred. Please check you internet connectivity."
				);
			});

		return false;
	}

	async function submit(values) {
		try {
			let res = await _fetch(`${process.env.REACT_APP_API_URL}/user`, {
				method: 'PATCH',
				body: values ?? {
					organization_name: '',
					role: '',
					nationality: '',
					gender: '',
					dob: ''
				}
			});
			let result = await res.json();
			if (res.status === 200) {
				toast.success('Profile updated successfully!');
			} else {
				toast.error(result.message);
			}
		} catch (err) {
			console.log(err);
		}
	}

	return (
		<Row gutter={[16, 16]} className="w-75 m-auto py-5">
			<Col xs={24} md={12}>
				<Card className="shadow-sm bg-white rounded mb-3">
					<Form
						name="user-info"
						labelCol={{ span: 24 }}
						wrapperCol={{ span: 24 }}
						initialValues={{ remember: true }}
						onFinish={submit}
						autoComplete="off"
					>
						<Form.Item>
							<h5 className="mb-0">
								My Info
							</h5>
						</Form.Item>
						<Form.Item label={<div className="fw-bold">Name</div>}>
							<Text>{first_name} {last_name}</Text>
						</Form.Item>
						<Form.Item label={<div className="fw-bold">Email</div>}>
							<Text>{email}</Text>
						</Form.Item>
						<Form.Item label={<div className="fw-bold">Name</div>} name='organization_name'>
							<Input defaultValue={organization_name} />
						</Form.Item>
						<Form.Item label={<div className="fw-bold">Current Role</div>} name='role'>
							<Input defaultValue={role} />
						</Form.Item>
						<Form.Item label={<div className="fw-bold">Nationality</div>} name='nationality'>
							<Input defaultValue={nationality} />
						</Form.Item>
						<Form.Item label={<div className="fw-bold">Gender</div>} name='gender'>
							<Select allowClear defaultValue={gender}>
								<Option value="male">Male</Option>
								<Option value="female">Female</Option>
								<Option value="other">Others</Option>
								<Option value="prefer_not_to_say">Prefer not to say</Option>
							</Select>
						</Form.Item>
						<Form.Item label={<div className="fw-bold">Date Of Birth</div>} name='dob'>
							<DatePicker defaultValue={dob} />
						</Form.Item>
						<Form.Item>
							<Button type="primary" htmlType="submit">
								Save
							</Button>
						</Form.Item>
					</Form>
					<Row gutter={[16, 16]}>
						<Col span={24}>

						</Col>
						<Col span={24}>
						</Col>
					</Row>
				</Card>

				<Card className="shadow-sm bg-white rounded">
					<form onSubmit={handlePasswordSubmit}>
						<Row gutter={[16, 16]}>
							<Col span={24}>
								<h5 className='mb-0'>Change Password</h5>
							</Col>
							<Col span={24}>
								<Input.Password
									placeholder="Old Password"
									onChange={(e) => {
										setCurrentPassword(e.target.value);
									}}
								/>
							</Col>
							<Col span={24}>
								<Input.Password
									placeholder="New Password"
									iconRender={(visible) =>
										visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
									}
									onChange={(e) => {
										setPassword(e.target.value);
									}}
								/>
							</Col>
							<Col span={24}>
								<Input.Password
									placeholder="Re-Enter New Password"
									iconRender={(visible) =>
										visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
									}
									onChange={(e) => {
										setConfirmPassword(e.target.value);
									}}
								/>
							</Col>
							{
								(passwordSuccess || error) ?
									<Col span={24}>
										{
											passwordSuccess &&
											(
												<Text type="success">
													Password updated successfully.
												</Text>
											)
										}
										{
											error ?
												(
													<Text type="danger">
														{error}
													</Text>
												)
												:
												null
										}
									</Col>
									:
									''
							}
							<Col span={24}>
								<Button type="primary" htmlType="submit">
									Save
								</Button>
							</Col>
						</Row>
					</form>
				</Card>

			</Col>
			<Col xs={24} md={12}>
				<Card className="shadow-sm bg-white rounded mb-3">
					<h5>Enrolled Courses</h5>
					<List
						header={
							<Row className="w-100" gutter={8}>
								<Col span={9}>Course</Col>
								<Col span={5}>Start Date</Col>
								<Col span={5}>End Date</Col>
								<Col span={5}>Status</Col>
							</Row>
						}
						dataSource={courses}
						renderItem={(item) => (
							<List.Item>
								<Row className="w-100" gutter={8}>
									<Col span={9}>{item.name}</Col>
									<Col span={5}>{moment(item.valid_from).format('ll')}</Col>
									<Col span={5}>{moment(item.valid_upto).format('ll')}</Col>
									<Col span={5}>{item.completion === 0 ? 'Not Started' : item.completion === 100 ? 'Completed' : 'In Progress'}</Col>
								</Row>
							</List.Item>
						)}
					/>
				</Card>
				<Card className="shadow-sm bg-white rounded">
					<h5>Transactions</h5>
					<List
						header={
							<Row className="w-100" gutter={8}>
								<Col span={14}>Date</Col>
								<Col span={5}>Amount</Col>
								<Col span={5}>Status</Col>
							</Row>
						}
						dataSource={order}
						renderItem={(item) => (
							<List.Item>
								<Row className="w-100" gutter={8}>
									<Col span={14}>{moment(item.updatedAt).format('ll')}</Col>
									<Col span={5}>{item.total_amount}</Col>
									<Col span={5}>{item.status}</Col>
								</Row>
							</List.Item>
						)}
					/>
				</Card>
			</Col>
		</Row>
		// </div>
	);
}
