import "./styles.less";

export function Privacy() {
	return (
		<div id='tnc'>
			<main id="main">

				<section>
					<div className="section-title">
						<h2>Privacy Policy</h2>
					</div>

					<div className="container">
						<p>
							This privacy policy sets out how Unitive Technologies Limited uses and protects any information that you give Unitive Technologies Limited when you use this website (https://proper-training.com/ ). Unitive Technologies Ltd is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will only be used in accordance with this privacy statement. If you provide information to us, you consent to our use of the data as specified. If you are under the age of 16 then you must seek parental consent before providing information to us. Unitive Technologies Limited may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes. This policy is effective from 01st January 2022.
						</p>

						<h5>About us</h5>
						<p>
							Unitive Technologies Ltd will be the data controller in respect of your personal information that you submit to us or that we collect from you when you use our Site or that we collect about you as a prospective customer or employee. We are a company registered in England and Wales with company number 13817317 and registered office at 3 Churchill Ct, Manor Royal, Crawley,England,RH10 9LU. If you decide to use one of the Unitive Technologies services, our Terms of Service set out the terms upon which we collect and use personal information in the course of providing our services to you. If you have any queries or complaints about this Privacy and Cookie Policy or our Site, or would like to know what information we hold about you, our contact details are as follows:
							<br />
							Email:info@proper-traning.com
						</p>

						<h5>What we collect</h5>
						<p>
							We may collect the following information:
						</p>
						<ul>
							<li>
								name and job title
							</li>
							<li>
								contact information including email address and mobile numbers.
							</li>
							<li>
								demographic information such as postcode, preferences and interests
							</li>
							<li>
								other information relevant to customer surveys and/or offers.
							</li>
						</ul>

						<h5>What we do with the information we gather</h5>
						<p>
							We will only use the personal information in accordance with this Privacy and Cookie Policy and our Terms of Use. You agree that we may use your information for the following purposes:
						</p>
						<ul>
							<li>
								to provide you with access to our Site and to provide you with the information, products and services that you request from us (in accordance with our agreement with you);
							</li>
							<li>
								to provide you, or permit selected third parties to provide you with information about goods or services we feel may interest you. We will only contact you by electronic means (e-mail or SMS) or by telephone with information about goods and services similar to those which were the subject of a previous sale or negotiation of a sale to you or if you have opted in to receiving such communications;
							</li>
							<li>
								to provide customer service and support, deal with enquiries or notify you of changes to our Site;
							</li>
							<li>
								to improve our Site and ensure that content from our Site is presented in the most effective and optimal manner (in accordance with our legitimate business interests);
							</li>
							<li>
								to carry out aggregated and anonymised research about general consumer engagement with our Site and services (in accordance with our legitimate business interest);
							</li>
							<li>
								to enable us to enforce our legal rights, or to protect the rights, property or safety or our employees (we may have a legal obligation to do this, and otherwise will only do so in accordance with our legitimate business interest);
							</li>
							<li>
								to allow you to participate in interactive features of our service, when you choose to do so;
							</li>
							<li>
								as part of our efforts to keep our Site safe and secure (in accordance with our legitimate business interests);
							</li>
							<li>
								to consider your application, if you apply for a job with us; and
							</li>
							<li>
								to understand how we might be able to provide suitable services to you, if you are a prospective user of our services.
							</li>
						</ul>

						<h5>Disclosure of your information</h5>
						<p>
							Other than as expressly set out in this Privacy &amp; Cookie Policy or as otherwise required or permitted by law, we will not share, sell or distribute any of the information you provide to use without your consent.
							<br />
							You agree that we may share your information with:
						</p>
						<ul>
							<li>
								any company which is a member of our group;
							</li>
							<li>
								any third party that we work with where necessary for the purposes of granting you access to our Site and/or delivering to you any other service(s) that you request from us. For example, we might engage other third party sub-contractors or service providers to help us host our Site, manage our content or mailing lists;
							</li>
							<li>
								any affiliate or third party to whom disclosure is necessary to enable us to provide you with any services or products that you have requested through the Site which may be provided by such affiliates or third parties;
							</li>
							<li>
								in the event that we sell or buy any business or assets, in which case we will disclose your personal data to the prospective seller or buyer of such business or assets;
							</li>
							<li>
								if we or substantially all of our assets are acquired by a third party, in which case personal data held by us about our customers will be one of the transferred assets;
							</li>
							<li>
								any third parties where necessary to enable us to comply with any legal obligations, enforce our legal rights, or to protect the rights, property, or safety of our employees, customers, or where such disclosure may be permitted or required by law (including without limitation any court, regulator or other relevant authority);
							</li>
							<li>
								any selected third party that you consent to our sharing your information with for marketing purposes as we refer to above; and
							</li>
							<li>
								any advertisers and advertising networks that require the data to select and serve relevant adverts to you and others. We do not disclose information about identifiable individuals to our advertisers but we will provide them with aggregate and anonymised information about our users. We may also use such aggregate information to help advertisers reach the kind of audience they want to target and we may use the personal data we have collected from you to enable us to comply with our advertisers’ wishes by displaying their advertisement to that target audience.
							</li>
						</ul>

						<h5>Cookies</h5>
						<p>
							We use cookies to ensure that you get the most out of our Site. Cookies are small amounts of information in the form of text files which we store on the device you use to access the Site. Cookies allow us to monitor your use of our Site so that we can make improvements to your experience when you browse our Site and simplify your use of the Site. For example, a temporary cookie is used to keep track of your “session.” Without that temporary cookie (which is not stored after you quit your browser) you would have to log on every time you access a new page.
						</p>

						<p>
							If you do not wish for cookies to be installed on your device, you can change the settings of your browser or device to reject cookies. For more information about how to reject cookies using your internet browser settings, please consult the “Help” section of your internet browser (or alternatively visit http://www.aboutcookies.org). Please note that, if you do set your Internet browser to reject cookies, you may not be able to access all of the functions of the Site and your experience may be less satisfying.
						</p>

						<p>
							The name of the cookies used by the Site and the purposes for which these cookies are used are as follows:
						</p>

						<p>
							Google Analytics<br /><br />
							We use Google Analytics to analyse how website visitors interact with our website. We use this information to identify trends and help us improve our website. The cookies collect information in an anonymous form about visitors to the site, including what pages they visited, how long they stay on each page for and the number of clicks.
						</p>

						<h5>
							Controlling your personal information
						</h5>

						<p>
							You have the right to ask us not to process your personal data for marketing purposes. We will usually inform you (before collecting your data) if we intend to use your data for such purposes or if we intend to disclose your information to any third party for such purposes.
						</p>

						<p>
							You may choose to restrict the collection or use of your personal information in the following ways:
						</p>

						<ul>
							<li>
								whenever you are asked to fill in a form on the website, look for the box that you can click to indicate that you do not want the information to be used by anybody for direct marketing purposes
							</li>
							<li>
								if you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by emailing us at info@proper-training.com. You may also request deletion of your personal information by emailing us at this address.
							</li>
						</ul>

						<p>
							You are entitled to know what personal data we hold about you and you may ask us to make any necessary changes to ensure that it is accurate and kept up to date. In certain circumstances you may also be entitled to request that we delete certain information that we hold about you.

						</p>

						<p>
							If you believe that any information we are holding on you is incorrect or incomplete, please write to or email us as soon as possible, at the above address. We will promptly correct any information found to be incorrect.
						</p>

						<h5>Security and retention of your data</h5>

						<p>
							We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.
						</p>

						<p>
							We will retain your information for a reasonable period or as long as the law otherwise permits.

						</p>

						<p>
							Transmission of information over the internet can be insecure and although we employ measures to protect your information from unauthorised access, you acknowledge that we cannot always guarantee the security of any information sent over the internet.

						</p>

						<p>
							Where possible all information you provide to us is stored on secure servers which are situated in the European Economic Area (EEA). In some instances (e.g. where we engage the services of third parties who utilise servers based outside the EEA) the information you provide to us may be stored and processed outside the EEA. In such cases, we will take steps to ensure adequate safeguards (e.g. EU-U.S. Privacy Shield) are in place to protect data transfers.
						</p>

						<p>
							Where we have given you (or where you have chosen) a password which enables you to access certain parts of our Site, you are responsible for keeping this password confidential. We ask you not to share a password with anyone.
						</p>

						<h5>Links to other websites</h5>

						<p>
							Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.
						</p>

					</div>
				</section>

			</main>
		</div>
	);
}
