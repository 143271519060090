import { Avatar, Col, Dropdown, Row, Menu, Badge } from 'antd';
import React, { useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { CloseCircleOutlined, CloseOutlined, MenuOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { FiShoppingCart } from 'react-icons/fi';

import { logout } from '../../store/action/user';
import useFetch from '../../_fetch';
import './styles.less';

export default function Header() {

    let _fetch = useFetch();

    const [mobileNavVisible, setMobileNavVisible] = useState(false);
    const [, , removeCookie] = useCookies(['userInfo']);
    const { first_name, last_name, _id } = useSelector(st => st.user);
    let dispatch = useDispatch();

    const _logout = async () => {

        try {
            let res = await _fetch(`${process.env.REACT_APP_API_URL}/user/login`, { method: "DELETE" });
            let result = await res.json();
            if (res.status === 200) {
                dispatch(logout());
                removeCookie('userInfo');
            } else {
                toast.error(result.response);
            }
        } catch (err) {
            console.log(err);
            toast.error('You are offline, please check your network connection');
        } finally {
        }
    }

    const menu = (
        <Menu>
            <Menu.Item key="0">
                <NavLink
                    to='/profile'
                >
                    Profile
                </NavLink>
            </Menu.Item>
            <Menu.Item key="1"
                onClick={_logout}
            >
                Logout
            </Menu.Item>
        </Menu>
    );

    const breakpoints = useBreakpoint();
    let location = useLocation();
    const visible_routes = ['/login', '/register', '/', '/about', '/faq', '/contact', '/forgot_password', '/reset_pass'];
    const [cookie] = useCookies(['cart']);

    return (
        <React.Fragment>
            {/* <div id="topbar" className={visible_routes.includes(location.pathname) ? '' : 'd-none'}>
                <div className="container">
                    <h5 className='mb-1'>SALE! SALE! SALE!</h5>
                    <p>Buy any course get 30% off</p>
                </div>
            </div> */}
            <div className="header position-relative">
                <Row className='h-100'>
                    <Col xs={12} md={16} lg={4} xl={{ span: 2, offset: 2 }}>
                        <div className='logo ps-2'>
                            <Link to='/'>
                                {/* <h2 className='mb-0 text-white'>{APP_NAME}</h2> */}
                                <img src='/logo2.png' alt='Proper Trainings' />
                            </Link>
                        </div>
                    </Col>
                    <Col xs={0} lg={16} xxl={17} className='ps-3 d-lg-flex flex-row align-items-center'>
                        <NavLink
                            to='/'
                            className={({ isActive }) => "mx-3 menu-item" + (isActive ? ' active' : '')}
                        >
                            Home
                        </NavLink>
                        <Link to='/#about' className="mx-3 menu-item">
                            About Us
                        </Link>
                        <Link to='/#courses' className="mx-3 menu-item">
                            Courses
                        </Link>
                        <Link to='/#testimonial' className="mx-3 menu-item">
                            Testimonials
                        </Link>
                        <NavLink
                            to='/faq'
                            className={({ isActive }) => "mx-3 menu-item" + (isActive ? ' active' : '')}
                        >
                            FAQ
                        </NavLink>
                        <NavLink
                            to='/contact'
                            className={({ isActive }) => "mx-3 menu-item" + (isActive ? ' active' : '')}
                        >
                            Contact
                        </NavLink>
                        {
                            _id &&
                            <NavLink
                                to='/dashboard'
                                className={({ isActive }) => "mx-3 menu-item" + (isActive ? ' active' : '')}
                            >
                                Dashboard
                            </NavLink>
                        }
                    </Col>
                    <Col xs={9} md={6} lg={4} xxl={3} className='d-flex flex-row align-items-center justify-content-end pe-lg-5'>
                        {
                            _id ?
                                <React.Fragment>
                                    <Link to='/cart' className='me-4'>
                                        <Badge count={cookie.cart ? cookie.cart.split(',').length : 0}>
                                            <FiShoppingCart size={25} color='#2717bd' />
                                        </Badge>
                                    </Link>
                                    <Dropdown overlay={menu}>
                                        <div className='d-flex align-items-center'>
                                            <Avatar size={30} style={{ background: '#2717bd' }}>{first_name ? first_name[0] : ''}</Avatar>
                                            <span
                                                className={'ms-2 ' + (breakpoints.md ? ' d-inline' : ' d-none')}
                                            >
                                                {first_name} {last_name}
                                            </span>
                                        </div>
                                    </Dropdown>
                                </React.Fragment>
                                :
                                breakpoints.md ?
                                    <React.Fragment>
                                        <NavLink
                                            to='/login'
                                            className={({ isActive }) => "mx-3 menu-item" + (isActive ? ' active' : '')}
                                        >
                                            Login
                                        </NavLink>
                                        <NavLink
                                            to='/register'
                                            className={({ isActive }) => "mx-3 menu-item" + (isActive ? ' active' : '')}
                                        >
                                            Register
                                        </NavLink>
                                    </React.Fragment>
                                    :
                                    null
                        }
                    </Col>
                    <Col xs={3} md={2} lg={0}>
                        <div className='d-flex align-items-center w-100 h-100 pe-3 justify-content-end'>
                            {
                                mobileNavVisible ?
                                    <CloseOutlined onClick={() => setMobileNavVisible(!mobileNavVisible)} />
                                    :
                                    <MenuOutlined onClick={() => setMobileNavVisible(!mobileNavVisible)} />
                            }
                        </div>
                    </Col>
                </Row>

                <div
                    className={'w-100 position-absolute' + (breakpoints.lg ? ' d-none' : mobileNavVisible ? '' : 'd-none')}
                    style={{ top: 0, left: 0, height: '100vh', zIndex: 500 }}
                    onClick={() => setMobileNavVisible(!mobileNavVisible)}
                >
                    <div className={'mobile-nav-popup' + (breakpoints.lg ? ' d-none' : mobileNavVisible ? ' d-flex flex-column' : ' d-none')}>
                        <div className='d-flex w-100 justify-content-between align-item-center bg-white p-2'>
                            <img src='/logo2.png' className='logo' alt="Proper Training" />
                            <div
                                onClick={() => {
                                    console.log('ok')
                                    setMobileNavVisible(!mobileNavVisible)
                                }}
                            >
                                <CloseCircleOutlined style={{ fontSize: 25 }} />
                            </div>
                        </div>
                        <div className='menu'>
                            <NavLink
                                to='/'
                                className={({ isActive }) => "mx-3 menu-item" + (isActive ? ' fw-bold' : '')}
                            >
                                Home
                            </NavLink>
                            {
                                _id &&
                                <NavLink
                                    to='/dashboard'
                                    className={({ isActive }) => "mx-3 menu-item" + (isActive ? ' fw-bold' : '')}
                                >
                                    Dashboard
                                </NavLink>
                            }
                            <Link to='/#about' className="mx-3 menu-item">
                                About Us
                            </Link>
                            <Link to='/#courses' className="mx-3 menu-item">
                                Courses
                            </Link>
                            <Link to='/#testimonial' className="mx-3 menu-item">
                                Testimonials
                            </Link>
                            <Link to='/faq' className="mx-3 menu-item">
                                FAQ
                            </Link>
                            <Link to='/contact' className="mx-3 menu-item">
                                Contact
                            </Link>
                            {
                                !_id ?
                                    <React.Fragment>
                                        <NavLink
                                            to='/login'
                                            className={({ isActive }) => "mx-3 menu-item" + (isActive ? ' fw-bold' : '')}
                                        >
                                            Login
                                        </NavLink>
                                        <NavLink
                                            to='/register'
                                            className={({ isActive }) => "mx-3 menu-item" + (isActive ? ' fw-bold' : '')}
                                        >
                                            Sign Up
                                        </NavLink>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <NavLink
                                            to='/profile'
                                            className={({ isActive }) => "mx-3 menu-item" + (isActive ? ' fw-bold' : '')}
                                        >
                                            Profile
                                        </NavLink>
                                        <NavLink
                                            onClick={_logout}
                                            to='/'
                                            className={({ isActive }) => "mx-3 menu-item" + (isActive ? ' fw-bold' : '')}
                                        >
                                            Logout
                                        </NavLink>
                                    </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}