import React from "react";
import { Card, Col, Button } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const { Meta } = Card;

export const CourseCard = ({ course }) => {

    let { _id } = useSelector(st => st.user);
    const navigate = useNavigate();

    return (
        <Col xs={24} md={12} xl={8} xxl={6}>
            <Card
                cover={
                    <img
                        alt={course.name}
                        src={course.banner_link}
                        className="img img-fluid"
                    />
                }
                className="course-card pointer h-100"
            >

                <h5>
                    {course.name}
                </h5>

                <div className="d-flex mt-4">
                    <Button
                        className="mx-auto" shape="round" type="primary"
                        onClick={(ev) => {
                            if (_id) {
                                navigate(`/course_overview?id=${course._id}`)
                            } else {
                                navigate('/login');
                            }
                        }}
                    >
                        Start Learning
                    </Button>
                </div>
            </Card>
        </Col>
    )
}
