export default function useFetch() {

    /**
     * 
     * @param {String} url url of the api / service 
     * @param {Object} obj options to pass method, body, token for the request 
     * @param {('GET'|'POST'|'PATCH'|'DELETE')} obj.method http method to use for request
     * @param {Object} obj.body http message body if request type is POST or PATCH
     * @returns 
     */
    async function _fetch(url, obj = {}) {

        let headers = {};
        let { method = 'GET', body = undefined, } = obj;

        if (body) {
            headers['Content-Type'] = 'application/json';
        }

        const options = {
            headers,
            body: JSON.stringify(body),
            method,
            credentials: 'include'
        };

        return fetch(url, options);
        
    }

    return _fetch;

};
