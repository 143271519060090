import { MailOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Input, Row } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import useFetch from '../../_fetch';
import './styles.less';
import { toast } from 'react-toastify';

export function ForgotPassword() {

	const [form, setForm] = useState({
		email: '',
		password: ''
	});
	const [loading, setLoading] = useState(false);

	let _fetch = useFetch();

	const _login = async () => {
		if (!form.email) {
			toast.error('Please enter your email');
			return;
		}
		try {
			setLoading(true);
			let res = await _fetch(`${process.env.REACT_APP_API_URL}/user/forgot_password?email=${form.email}`);
			let result = await res.json();
			if (res.status === 200) {
				setForm({});
				return toast.success('Please check you email for account recovery instructions');
			} else {
				toast.error(result.response);
			}
		} catch (err) {
			console.log(err);
			toast.error('You are offline, please check your network connection');
		} finally {
			setLoading(false);
		}
	}

	return (
		<div className='container h-100 d-flex flex-column'>
			<Row justify='center' className='my-5'>
				<Col>
					<Card>
						<h3 className='mb-0'>Recover Your Account</h3>
						<Divider className='my-4' />
						<p className='mb-3'>Please enter your email to reset password.</p>
						<Input
							placeholder='Enter Your Email' prefix={<MailOutlined />}
							className='login-mb-40'
							onChange={(ev) => setForm({ ...form, email: ev.target.value })}
							value={form.email} type="email"
						/>
						<div className='d-flex'>
							<Button
								type='primary' disabled={loading}
								loading={loading}
								onClick={_login}
								className='text-center'
							>
								Recover Account
							</Button>
						</div>
						<Divider className='my-4' />
						<Row gutter={8}>
							<Col span={12} className='text-start align-middle'>
								<Link to='/login'>Login to your account</Link>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
		</div>
	)

}