import { Button, DatePicker, Form, Input, Select } from "antd";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useFetch from "../../_fetch";

const { Option } = Select;

export function UserInfoForm() {

    let _fetch = useFetch();
    let navigate = useNavigate();

    async function submit(values) {
        try {
            let res = await _fetch(`${process.env.REACT_APP_API_URL}/user`, {
                method: 'PATCH',
                body: values ?? {
                    organization_name: '',
                    role: '',
                    nationality: '',
                    gender: '',
                    dob: ''
                }
            });
            let result = await res.json();
            if (res.status === 200) {
                toast.success('Profile updated successfully!');
                navigate('/dashboard');
            } else {
                toast.error(result.message);
            }
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className="container py-5">
            <div className="row">
                <div className="col-12 col-lg-6 offset-lg-3">

                    <h5 className="mb-4">
                        Please fill this form to help us provide you a better experience
                    </h5>

                    <Form
                        name="user-info"
                        labelCol={{ xs: 24, md: 8 }}
                        wrapperCol={{ xs: 24, md: 16 }}
                        initialValues={{ remember: true }}
                        onFinish={submit}
                        autoComplete="off"
                    >
                        <Form.Item label='Organization Name' name='organization_name'>
                            <Input />
                        </Form.Item>
                        <Form.Item label='Current Role' name='role'>
                            <Input />
                        </Form.Item>
                        <Form.Item label='Nationality' name='nationality'>
                            <Input />
                        </Form.Item>
                        <Form.Item label='Gender' name='gender'>
							<Select allowClear>
								<Option value="male">Male</Option>
								<Option value="female">Female</Option>
								<Option value="other">Prefer not to say</Option>
							</Select>
                        </Form.Item>
                        <Form.Item label='Date Of Birth' name='dob'>
							<DatePicker />
                        </Form.Item>
                        <Form.Item wrapperCol={{xs: 24, md: {span: 16, offset: 8}}}>
                            <Button type='link' htmlType="button" onClick={()=>submit(null)}>Skip</Button>
                            <Button type='primary' htmlType="submit" className="ms-3">Submit</Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    )
}