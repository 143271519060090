import { Button } from 'antd';
import React, { useState } from 'react';
import { BsEnvelope, BsGeoAlt, BsPhone } from 'react-icons/bs';
import validator from 'validator';

import { sanitize } from '../../sanitizer';
import useFetch from '../../_fetch';

import "./styles.less";

const initState = {
	name: '',
	subject: '',
	message: '',
	email: ''
};

export function ContactUs() {

	const [data, setData] = useState(initState);
	const [message, setMessage] = useState('');

	const [err, setErr] = useState('');
	const [sending, setSending] = useState(false);
	let _fetch = useFetch();

	function submit(event) {
		event.preventDefault();

		setErr('');

		if (!validator.isEmail(data.email)) {
			setErr("Please enter a valid email");
			return;
		}

		if (data.name.length < 3) {
			setErr("Please enter your name");
			return;
		}

		if (!data.message) {
			setErr("Please enter a message for us");
			return;
		}

		setSending(true);

		_fetch(`${process.env.REACT_APP_API_URL}/user/support_ticket`, {
			method: "POST",
			body: {
				name: data.name,
				message: data.message,
				email: data.email,
				subject: data.subject
			}
		})
			.then(res => {
				if (res.status === 200) {
					setData(initState);
					setMessage('Thank you for your message. We will get back to you in 48 hours.');
				} else {
					setErr('Some error occurred please try again later');
				}
			})
			.catch(err => {
				console.log(err);
				setErr('Some error occurred please try again later');
			})
			.finally(() => {
				setSending(false);
			})

		return false;

	}

	return (
		<div id="contactus">
			<main id="main">
				<section id="contact" className="contact">
					<div className="container">

						<div className="section-title">
							<h2>Contact</h2>
							<p>Have a question about training.Please contact using the form below: </p>
						</div>
					</div>

					<div>
						<iframe
							title="Location"
							style={{ border: 0, width: '100%', height: '350px' }}
							src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2482.958918344939!2d-0.11277691009109791!3d51.51396968293252!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xffb18ea71f030bee!2sTaskial%20Solutions!5e0!3m2!1sen!2sin!4v1604725651455!5m2!1sen!2sin"
							frameBorder={0}
							allowFullScreen
						></iframe>
					</div>

					<div className="container">
						<div className="row mt-5">

							<div className="col-lg-4">
								<div className="info">
									<div className="address">
										<i><BsGeoAlt /></i>
										<h4>Location:</h4>
										<p>UNITIVE TECHNOLOGIES LIMITED, 3 Churchill Ct, Manor Royal, Crawley, RH10 9LU</p>
									</div>

									<div className="email">
										<i><BsEnvelope /></i>
										<h4>Email:</h4>
										<p>info@proper-training.com</p>
									</div>

									<div className="phone">
										<i><BsPhone /></i>
										<h4>Call:</h4>
										<p>+44 7405510456</p>
									</div>

								</div>

							</div>

							<div className="col-lg-8 mt-5 mt-lg-0">

								<form className="php-email-form" onSubmit={submit}>
									<div className="row">
										<div className="col-md-6 form-group">
											<input
												type="text" name="name" className="form-control"
												id="name" placeholder="Your Name" required value={data.name}
												onChange={ev => setData({ ...data, name: sanitize(ev.target.value) })}
											/>
										</div>
										<div className="col-md-6 form-group mt-3 mt-md-0">
											<input
												type="email" className="form-control" value={data.email}
												name="email" id="email" placeholder="Your Email"
												required onChange={ev => setData({ ...data, email: sanitize(ev.target.value) })}
											/>
										</div>
									</div>

									<div className="form-group mt-3">
										<input
											type="text" className="form-control" name="subject"
											id="subject" placeholder="Subject" required value={data.subject}
											onChange={ev => setData({ ...data, subject: sanitize(ev.target.value) })}
										/>
									</div>
									<div className="form-group mt-3">
										<textarea
											className="form-control" name="message" value={data.message}
											rows="5" placeholder="Message" required
											onChange={ev => setData({ ...data, message: sanitize(ev.target.value) })}
										></textarea>
									</div>
									<div className="my-3">
										{err ? <div className="error-message">{err}</div> : ''}
										{message ? <div className="sent-message">{message}</div> : ''}
									</div>
									<div className="text-center">
										<Button
											type="primary" htmlType='submit'
											loading={sending}
										>
											Send Message
										</Button>
									</div>
								</form>

							</div>

						</div>

					</div>
				</section>
			</main>
		</div>
	);
}
