import { Collapse } from 'antd';

import "./styles.less";

const { Panel } = Collapse;

export function FAQ() {

	return (
		<div id="faq_page">
			<main id="main">

				<section id="faq" className="faq">
					<div className="container">

						<div className="section-title">
							<h2>Frequently Asked Questions</h2>
						</div>

						<div className="faq-list">
							<Collapse defaultActiveKey={['1']} bordered={false}>
								<Panel
									header="What is Proper Training?"
									key="1"
								>
									<p>
										We are a training company focused on cyber security,Application security,Devops,DevSecops,Cloud and container security.
									</p>
								</Panel>
								<Panel
									header="Do you give online training?"
									key="2"
								>
									<p>
										We offer online mentor based training,face to face and self paced training.
									</p>
								</Panel>
								<Panel
									header="How can I subscribe to your courses?"
									key="3"
								>
									<p>
										You can subscribe to our website updates using this link().We will update you whenever new courses are added to our list.
									</p>
								</Panel>
								<Panel
									header="How long will I have access to the courses which are bought on proper training?"
									key="4"
								>
									<p>
										You will have access to the videos till the course expiry date.
									</p>
								</Panel>
								<Panel
									header="I am from a non IT background.Can I take this training to enter the Cyber Security sector?"
									key="4"
								>
									<p>
										Yes,you can take our starter course which doesn't need any coding experience.
									</p>
								</Panel>
							</Collapse>
						</div>

					</div>
				</section>

			</main>
		</div>
	);
}
